import React,{useState} from "react";
import Select from "react-select";

const SelectOption = ({ selectLabel,value,type,fullvalue,handleChange }) => {
  const CustomStyle = {
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#081F43' : 'white',
      color:  state.isSelected ? 'white' : '#081F43',
      menu: base => ({
        ...base,
        zIndex: 100,
        backgroundColor:'red'
      }),
    })
  }


  const options = [
    { value: "Erkek", label: "Erkek" },
    { value: "Kadın", label: "Kadın" },
  ];



  return ( 

    type == "highSchool"?
    <div className="relative">
       <label
      htmlFor="multi-select"
      className=" "
    >{selectLabel}
    </label>
    
    <Select
     defaultValue={fullvalue.filter(x=> x.label ==value)}
      onChange={e => handleChange(e)}
      options={fullvalue}
      styles={CustomStyle}
      placeholder="Üniversite Seçiniz."
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
   
  </div>: 
    type == "cityEducation"?
    <div className="relative">
        <label
      htmlFor="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transhtmlForm -translate-y-4 scale-75 top-0  origin-[0] bg-white   peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
    <Select
     defaultValue={fullvalue.filter(x=> x.label ==value)}
      onChange={e => handleChange(e,"cityEducation")}
      options={fullvalue}
      styles={CustomStyle}
      placeholder="İl Seçiniz."
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500  py-2 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
  
  </div>: 
    type == "educationprogram"?
    <div className="relative">
         <label
      htmlFor="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transhtmlForm -translate-y-4 scale-75 top-0  origin-[0] bg-white  py-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
    <Select
     defaultValue={fullvalue.filter(x=> x.label ==value)}
      onChange={e => handleChange(e,"educationprogram")}
      options={fullvalue}
      styles={CustomStyle}
      placeholder="Program Seçiniz."
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
 
  </div>: 
    type == "countries"?
    <div className="relative">
    <Select
     defaultValue={fullvalue.filter(x=> x.label ==value)}
      onChange={e => handleChange(e,"countries")}
      options={fullvalue}
      styles={CustomStyle}
      placeholder="Ülke Seçiniz."
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
    <label
      htmlFor="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transhtmlForm -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
  </div>: 
    type == "city"?
    <div className="relative">
    <Select
         defaultValue={fullvalue.filter(x=> x.label ==value)}
       onChange={e => handleChange(e,"city")}
      options={fullvalue}
      styles={CustomStyle}
      placeholder="Aradığınız işte ki ili seçiniz"
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
    <label
      htmlFor="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transhtmlForm -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
  </div>: 
    type == "district"?
    <div className="relative">
        <label
      htmlFor="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transhtmlForm -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
    <Select
         defaultValue={fullvalue.filter(x=> x.label ==value)}
                onChange={e => handleChange(e,"district")}
      options={fullvalue}
      styles={CustomStyle}
      placeholder="Aradığınız işte ki ilçeyi seçiniz"
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
  
  </div>:
    type == "district"?
    <div className="relative">
    <Select
                onChange={e => handleChange(e,"district")}
                defaultValue={fullvalue.filter(x=> x.value ==value)}
      options={fullvalue}
      styles={CustomStyle}
      placeholder="İşinizi Seçiniz."
      id="multi-select"
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
    <label
      htmlFor="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transhtmlForm -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
  </div>: 
    type == "city"?
    <div className="relative">
    <Select
                onChange={e => handleChange(e,"city")}
                defaultValue={fullvalue.filter(x=> x.value ==value)}
      options={fullvalue}
      styles={CustomStyle}
      placeholder="İşinizi Seçiniz."
      id="multi-select"
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
    <label
      htmlFor="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transhtmlForm -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
  </div>: 
    type == "job"?
  <div className="relative">
  <Select
              onChange={e => handleChange(e,"job")}
              defaultValue={fullvalue.filter(x=> x.value ==value)}
    options={fullvalue}
    placeholder="İşinizi Seçiniz."
    id="multi-select"
    className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
  />
  <label
    htmlFor="multi-select"
    className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transhtmlForm -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
  >{selectLabel}
  </label>
</div>:  
 
    type == "gender"?
      <div className="relative">
      <Select
        defaultValue={options.filter(x=> x.value ==value)}
        options={options}
        onChange={e => handleChange(e,"gender")}
        placeholder="Cinsiyet Seçiniz."
        id="multi-select"
        styles={CustomStyle}
        className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
      />
      <label
        htmlFor="multi-select"
        className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transhtmlForm -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
      >{selectLabel}
      </label>
    </div> :
 type == "jobs"? 
    <div className="relative">
    <Select
      onChange={e => handleChange(e,"jobs")}
      defaultValue={fullvalue.filter(x=> x.value ==value)}
      options={fullvalue}
      placeholder="Aranılan Pozisyon..."
      id="multi-select"
      styles={CustomStyle}
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
    <label
      htmlFor="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transhtmlForm -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
  </div>
  : 
    <div className="relative">
    <Select
      options={options}
      placeholder="Select Option..."
      id="multi-select"
      styles={CustomStyle}

      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
    <label
      htmlFor="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transhtmlForm -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
  </div>
      
 
  );
  };
export default SelectOption;
