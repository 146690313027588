import React, { useState,useEffect } from "react";
import { Link,useLocation } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormGroup,
  FormFeedback,
  Alert
} from "reactstrap";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-datepicker/dist/react-datepicker.css";
import "react-rangeslider/lib/index.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import {
  addApply as onAddApply,
  getApply as onGetApply,
} from "store/contacts/actions";

import moment from "moment";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
import styled from 'styled-components'

const StyledLoader = styled(LoadingOverlay)`
  height: 400px;
  overflow: scroll;
  .MyLoader_overlay {
    background: rgba(255, 255, 255, 0.5);
  }
  &.MyLoader_wrapper--active {
    overflow: hidden;
  }
`

const EcommerceAddProduct = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const state = location.state;

  const checkAdress = (values) => {

let data = JSON.stringify({
  "action": "authenticateMember",
  "params": {
    "username": "kpssporokullari",
    "password": "Kpsso4201+",
    "language": "tr"
  },
  "SP": {
    "currentCompanyId": 3429,
    "platform": "3rdParty",
    "language": "tr"
  }
});

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://bridgeapi.meram.bel.tr/http',
  headers: { 
    'Content-Type': 'application/json'
  },
  data : data
};
{/* 
axios.request(config)
.then((response) => {

console.log("şmdsşlf",state)
  
let data = JSON.stringify({
  "action": "getCollocutorFromNvi",
  "params": {
    "identityNumber": values.tcnumber,
    "birthdate": moment(values.datenumber).format('YYYY-MM-DD')
  },
  "SP": {
    "currentCompanyId": 3429,
    "platform": "3rdParty",
    "language": "tr",
    "originatorMemberId": 764,
    "proxyMemberId": null,
    "sessionId": (response.data).data.sessionId
  }
});


let config2 = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://bridgeapi.meram.bel.tr/http',
  headers: { 
    'Content-Type': 'application/json'
  },
  data : data
};

axios.request(config2)
.then((response) => {
  if(response.data.code == 0){
   if(response.data.data.address != null)
    if(response.data.data.address.townDescription == "meram")
    {
      setSuccess(true)
      showToast(true,null,values);

    
          setLoading(false);
      
     

    }else{
      setSuccess(false)
      setFalse(true)
      showToast(false);
    
          setLoading(false);
    
    }
    else{
      setLoading(false);
      showToast(false,"Meramda Oturanlar Başvurabilmektedir",null);
    }
  }else{
    setLoading(false);
    setFalseDesc(response.data.description)
    showToast(false,response.data.description,values);
  }
})
.catch((error) => {
  console.log(error);
});


})
.catch((error) => {
  console.log(error);
});
*/}

setSuccess(true)
showToast(true,null,values);


    setLoading(false);


  }


  //meta title
  document.title = "Başvuru Yap | MEGA - Kullanıcı Paneli";
  const [getSuccess, setSuccess] = useState(false);
  const [getFalse, setFalse] = useState(false);
  const [getFalseDesc, setFalseDesc] = useState("");

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      ID: '',
      tcnumber:  '',
      datenumber:  '',

    },
    validationSchema: Yup.object({
      tcnumber: Yup.string(11).required("Lütfen TC Numaranzı Eksiksiz Giriniz"),
      datenumber: Yup.string().required("Lütfen Doğum Tarihinizi Giriniz")
    }),
    onSubmit: (values) => {


      setLoading(true)
checkAdress(values);

   /*  if(years >= firstText && years <= secondText ){
    
      checkAdress(values);
     }else{

      showToast(false,"Yaşınız Uygun Değil!");
     } */
     // state.Course_Schedules.Workshop_Sessions.StartAge
     // state.Course_Schedules.Workshop_Sessions.FinishAge
      //
      },
  });



const addApply = (val) => {

 {/* let data = JSON.stringify({
    "action": "getCollocutorFromNvi",
    "params": {
      "identityNumber": val.tcnumber,
      "birthdate": moment(val.datenumber).format('YYYY-MM-DD').toString()
    },
    "SP": {
      "currentCompanyId": 3429,
      "platform": "3rdParty",
      "language": "tr",
      "originatorMemberId": 24,
      "proxyMemberId": null,
      "sessionId": "99628be6e6d65078b75a9100f202709a"
    }
  });
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://bridgeapi.meram.bel.tr/http',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  
  axios.request(config)
  .then((response) => {


    if(response.data.code == 0){
    if(response.data.data.address.townDescription == "meram")
    {
      setSuccess(true)
      showToast(true);

      var localDate = moment().local();

      let userDetail = localStorage.getItem("authUser");

      const newOrder = {
        Teacher_Workshop_ID:state._item.ID,
        Created_DateTime: localDate,
        Users_ID: userDetail.ID,
        IsActive:false
        }
        // save new order
        dispatch(onAddApply(newOrder));
        validation.resetForm();

    }else{
      setSuccess(false)
      setFalse(true)
      showToast(false);
    }
  }else{

    setFalseDesc(response.data.description)
    console.log((response.data.description));

  }


  })
  .catch((error) => {
    console.log("errr",error);
  }); */}

  setSuccess(true)
  showToast(true);

  var localDate = moment().local();

  let userDetail = localStorage.getItem("authUser");

  console.log("userDetail1123",JSON.parse(userDetail))
  const newOrder = {
    Teacher_Workshop_ID:null,
    Created_DateTime: localDate,
    Users_ID: userDetail.ID,
    IsActive:false
    }
    console.log("v12321123",newOrder)
    // save new order
    dispatch(onAddApply(newOrder));
    validation.resetForm();

}

function showToast(type,desc,values) {
  let ele = null
  let message = "";

   if(type == true){
     ele = "success"
     message = "Başarıyla Size Uygun Atölye ve Seanslara Yönlendirme Yapılmıştır!"
  } else{
     ele = "error"
     message = desc
  };
  const position = ["toast-top-right"];
  let toastType;
  const title = "Başlık";


  //Close Button
  const closeButton = true;

  //Debug
  const debug = false;

  //Progressbar
  const progressBar = true;

  //Duplicates
  const preventDuplicates = false;

  //Newest on Top
  const newestOnTop = true;

  //position class
  let positionClass = "toast-top-right";

  //Show Easing
  const showEasing = "swing";

  //Hide Easing
  const hideEasing = "linear";

  //show method
  const showMethod = "fadeIn";

  //Hide method
  const hideMethod = "fadeOut";

  //show duration
  const showDuration = 300;

  //Hide duration
  const hideDuration = 1000;

  //timeout
  const timeOut = 5000;

  //extended timeout
  const extendedTimeOut = 1000;

  //Fetch checked Type
 
   toastType = ele;
  

  toastr.options = {
    positionClass: positionClass,
    timeOut: timeOut,
    extendedTimeOut: extendedTimeOut,
    closeButton: closeButton,
    debug: debug,
    progressBar: progressBar,
    preventDuplicates: preventDuplicates,
    newestOnTop: newestOnTop,
    showEasing: showEasing,
    hideEasing: hideEasing,
    showMethod: showMethod,
    hideMethod: hideMethod,
    showDuration: showDuration,
    hideDuration: hideDuration
  };

  if(type == true){
   // sendMessage()
    navigate('/apply', { state: {
      update: true,
      tcNumber:values.tcnumber,
      birthdayDate:values.datenumber
      } })
    }
    
  // setTimeout(() => toastr.success(`Settings updated `), 300)
  //Toaster Types
  if (toastType === "info") toastr.info(message, title);
  else if (toastType === "warning") toastr.warning(message, title);
  else if (toastType === "error") toastr.error(message, title);
  else toastr.success(message, title);
}




  return (

    loading == true ? 
    <React.Fragment>
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumb */}
        <Breadcrumbs title="Başvuru" breadcrumbItem="Başvuru Yap" />
    <StyledLoader
    active={true}
    spinner={<BounceLoader />}
    classNamePrefix='MyLoader_'
  >
      <h5 className="font-size-25 text-center">
                  Yükleniyor ..
                </h5>
              
  </StyledLoader>
  </Container></div></React.Fragment> :
        

    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Başvuru" breadcrumbItem="Başvuru Yap" />

         

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Başvuru Yap</CardTitle>
                  <p className="card-title-desc mb-4">
                    Bütün Alanları Eksiksiz Doldurunuz!
                  </p>

                  <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productname">BAŞVURU YAPILACAK KİŞİNİN T.C. NUMARASI</Label>
                          <Input
                        name="tcnumber"
                        type="text"
                        placeholder="TC Numarası"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.tcnumber || ""}
                        invalid={
                          validation.touched.tcnumber && validation.errors.tcnumber ? true : false
                        }
                      />
                      {validation.touched.tcnumber && validation.errors.tcnumber ? (
                        <FormFeedback type="invalid">{validation.errors.tcnumber}</FormFeedback>
                      ) : null}
                        </div>
                    

                      </Col>

                      <Col sm="6">
                      <div className="mb-3">
                      <Label className="control-label">DOĞUM TARİHİ (Ör:01.01.1990)</Label>
                          <Input
                        name="datenumber"
                        type="date"
                        placeholder="TC Numarası"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.datenumber || ""}
                        invalid={
                          validation.touched.datenumber && validation.errors.datenumber ? true : false
                        }
                      />
                      {validation.touched.datenumber && validation.errors.datenumber ? (
                        <FormFeedback type="invalid">{validation.errors.datenumber}</FormFeedback>
                      ) : null}
                        </div>

                        
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn ">
                        Sorgula
                      </Button>
                      <Button type="cancel" color="secondary" className=" ">
                        İptal
                      </Button>

                    </div>

                                    
                  
                  <div className="">
                    <div id="liveAlertPlaceholder">
                      <Alert isOpen={getSuccess} toggle={() => {
                        setSuccess(false)
                      }}>
                     Başarıyla Kurslara Yönlendiriliyorsunuz
                      </Alert>

                      <Alert color="danger" role="alert" isOpen={getFalse} toggle={() => {
                        setSuccess(false)
                      }}>
                    {getFalseDesc}
                      </Alert>
                    </div>
                  </div>
                  </Form>
                </CardBody>
              </Card>

        
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
      
       );
}

export default EcommerceAddProduct
