import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import * as Yup from "yup";
import { useFormik } from "formik";
import  moment from "moment";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {Base64} from 'js-base64';

import CardProject from "./card-project";
import { map } from "lodash";

import {
  deleteOrder as onDeleteOrder,
} from "store/actions";

import {
  addWorkshopSubdomain as onAddWorkshopSubdomain,
  getWorkShop as onGetWorkShop,
  updateSubdomain as onUpdateWorkshop,
} from "store/contacts/actions";

import {
  Title,
  Date,
  Status,
}
  from "./EcommerceOrderCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Container,
  PaginationItem,
  PaginationLink
} from "reactstrap";

function EcommerceOrder() {

  //meta title
  document.title = "SUBDOMAIN | Mega - Admin Paneli";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [getWorkShopEdit, setWorkShopEdit] = useState(null);
 
  const [getFileBannerName, setFileBannerName] = useState(null);
  const [getFileMobileName, setFileMobileName] = useState(null);
  const [getHtmlData, setHtmlData] = useState();


  const [page, setPage] = useState(1);
  const [totalPage] = useState(5);

  const handlePageClick = page => {
    setPage(page);
  };
  // handle the status of the file upload
  const handleChangeStatusBanner = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileBannerName(result.file.filename)
        }
      }
    }
  }


  const handleChangeStatusMobile = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileMobileName(result.file.filename)
        }
      }
    }
  }
  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }


  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      ID: (getWorkShopEdit && getWorkShopEdit.ID) || '',

      startDate: (getWorkShopEdit && moment(getWorkShopEdit.SUBDOMAIN_StartDate).format('YYYY-MM-DD')) || '',
      finishDate: (getWorkShopEdit && moment(getWorkShopEdit.SUBDOMAIN_FinishDate).format('YYYY-MM-DD')) || '',
      title: (getWorkShopEdit && getWorkShopEdit.SUBDOMAIN_Title) || '',
      link: (getWorkShopEdit && getWorkShopEdit.SUBDOMAIN_Link) || '',

    },
    validationSchema: Yup.object({
      title: Yup.string().required("Başlığı Giriniz"),
      link: Yup.string().required("Linki Giriniz"),    
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateWorkshop = {
          ID: order ? order.ID : 0,
          SUBDOMAIN_Title: values.title,
          SUBDOMAIN_Desc:  Base64.encode(getHtmlData),
          SUBDOMAIN_Link: values.link,
       //   SUBDOMAIN_StartDate: values.startDate,
       //   SUBDOMAIN_FinishDate: values.finishDate,
          SUBDOMAIN_BannerPhotoLink:getFileBannerName,
          SUBDOMAIN_MobilePhotoLink:getFileMobileName
        };
        // update order
        dispatch(onUpdateWorkshop(updateWorkshop));
        validation.resetForm();
      } else {
        const newOrder = {
          SUBDOMAIN_Title: values["title"],
          SUBDOMAIN_Desc:  Base64.encode(getHtmlData),
          SUBDOMAIN_Link: values["link"],
       //   SUBDOMAIN_StartDate: values.startDate,
       //   SUBDOMAIN_FinishDate: values.finishDate,
          SUBDOMAIN_BannerPhotoLink:getFileBannerName,
          SUBDOMAIN_MobilePhotoLink:getFileMobileName
        };
        // save new order
        dispatch(onAddWorkshopSubdomain(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();
  const { orders } = useSelector(state => ({
    orders: state.contacts.workshop,
  }));

  useEffect(() => {
    if (orders && !orders.length) {
      dispatch(onGetWorkShop());
      setIsEdit(false);
    }
  }, [dispatch, orders]);

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders);
      setIsEdit(false);
    }
  }, [orders]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = arg => {
    const order = arg;
    setWorkShopEdit(order)
    setOrder(order);

    setIsEdit(true);
    setFileBannerName(order.SUBDOMAIN_BannerPhotoLink)
    setFileMobileName(order.SUBDOMAIN_MobilePhotoLink)
    setHtmlData(Base64.decode(order.SUBDOMAIN_Desc))

    toggle()
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.id) {
      dispatch(onDeleteOrder(order.id));
      setDeleteModal(false);
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Başlık',
        accessor: 'SUBDOMAIN_Title',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Title {...cellProps} />;
        }
      },
      {
        Header: "Banner Görseli",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.SUBDOMAIN_BannerPhotoLink ? (
              <div className="avatar-md">
                {cellProps.SUBDOMAIN_BannerPhotoLink != null ? <span className="avatar-title rounded-circle">
                  {cellProps.SUBDOMAIN_BannerPhotoLink.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className="avatar-xl"
                  src={'https://megasubdomain.stechomeyazilim.info/' + cellProps.SUBDOMAIN_BannerPhotoLink}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Mobil Görseli",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.SUBDOMAIN_MobilePhotoLink ? (
              <div className="avatar-lg">
                  {cellProps.SUBDOMAIN_MobilePhotoLink != null ? <span className="avatar-title rounded-circle">
                  {cellProps.SUBDOMAIN_MobilePhotoLink.charAt(0)}
                </span> : null }
              </div>
            ) : (
              <div>
                <img
                  className="avatar-xl"
                  src={'https://megasubdomain.stechomeyazilim.info/' + cellProps.SUBDOMAIN_MobilePhotoLink}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: 'Baş.Bit. Tarihi',
        accessor: 'SUBDOMAIN_StartDate',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },
      
  
      {
        Header: 'Aktiflik Durumu',
        accessor: 'IsActive',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },
    /* {
        Header: 'Detaylar',
        accessor: 'view',
        disableFilters: true,
        Cell: () => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={toggleViewModal}
            >
              Detayları Görüntüle
            </Button>);
        }
      },*/
      {
        Header: 'İşlemler',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleOrderClick(orderData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="YÖNETİM" breadcrumbItem="SUBDOMAIN" />

          <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}

          <Row>
            {/* Import Cards */}
            <CardProject projects={orders} />
          </Row>

          <Row>
            <Col lg="12">
              <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5">
                <PaginationItem disabled={page === 1}>
                  <PaginationLink
                    previous
                    href="#"
                    onClick={() => handlePageClick(page - 1)}
                  />
                </PaginationItem>
                {map(Array(totalPage), (item, i) => (
                  <PaginationItem active={i + 1 === page} key={i}>
                    <PaginationLink
                      onClick={() => handlePageClick(i + 1)}
                      href="#"
                    >
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={page === totalPage}>
                  <PaginationLink
                    next
                    href="#"
                    onClick={() => handlePageClick(page + 1)}
                  />
                </PaginationItem>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>

         
       
        </div>
      </div>
    </React.Fragment>
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;