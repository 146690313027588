import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link,useLocation } from "react-router-dom";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';
import {Base64} from 'js-base64';
import { map } from "lodash";
import axios from "axios";

import {
  deleteOrder as onDeleteOrder,
} from "store/actions";

import {
  addReferances as onAddReferances,
  updateReferances as onUpdateReferances,
  getReferances as onGetReferances,
  getWorkShop as onGetWorkShop,

} from "store/contacts/actions";



import {
  Name,
  Date
}
  from "./EcommerceOrderCol";
  import 'react-dropzone-uploader/dist/styles.css'

//redux
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import {
  Col,
  Row,
  Card,
  CardBody,
} from "reactstrap";

function EcommerceOrder() {

  //meta title
  document.title = "Başvurularım | Mega - Admin Paneli";


  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [getWPList, setWPList] = useState([]);

  const [order, setOrder] = useState(null);
  const [getEditSlider, setEditSlider] = useState(null);
  
  const [getAllMyApply, setAllMyApply] = useState([]);
  const [getAllMyApplyExtra, setAllMyApplyExtra] = useState([]);

  const location = useLocation();

  const state = location.state;

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (getEditSlider && getEditSlider.Referances_Name) || '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Başlığı Giriniz"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateSlider = {
          ID: order ? order.ID : 0,
          Referances_Name: values.title,
          Referances_Desc: Base64.encode(getHtmlData),
          Referances_LogoImage : getFileNameBanner ,
          Referances_ReferancesImage : getFileNameMobile,
        };
        // update order
        dispatch(onUpdateReferances(updateSlider));
        validation.resetForm();
      } else {
        const newOrder = {
          Referances_Name: values["title"],
          Referances_Desc: Base64.encode(getHtmlData),
          Referances_LogoImage : getFileNameBanner ,
          Referances_ReferancesImage : getFileNameMobile 
        };
        console.log("newOrder123",newOrder)
        // save new order
        dispatch(onAddReferances(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();
  const { orders } = useSelector(state => ({
    orders: state.contacts.referances,
  }));

  const { wp } = useSelector(state => ({
    wp: state.contacts.workshop,
  }));


  useEffect(() => {
    if(state != null){
      if(state.update == true){
        let authData = localStorage.getItem("authUser") != null ? JSON.parse(localStorage.getItem("authUser")) : null

        if(authData!=null){
          dispatch(onGetWorkShop(authData.ID));
          _getAllMyApply();

        }
      }
    }
   
    if (wp && !wp.length) {
      let authData = JSON.parse(localStorage.getItem("authUser"));

       dispatch(onGetWorkShop(authData.ID));
       setIsEdit(false);
     }
  }, [dispatch, wp]);
  
  useEffect(() => {

   if (orders && !orders.length) {
      dispatch(onGetReferances());
      setIsEdit(false);
    }

  }, [dispatch, orders]);



  useEffect(() => {
    setOrderList(orders);

  }, [orders]);

  useEffect(() => {
    _getAllMyApply();
    _getAllMyApplyExtra();
  }, []);

  

  const _getAllMyApply= async () => {
    let authData = JSON.parse(localStorage.getItem("authUser"));

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getSearchSUBDOMAINMega2/select/${authData.ID}`)
    .then((res) => {
      setAllMyApply(res.data)
    })
  }

  const _getAllMyApplyExtra= async () => {
    let authData = JSON.parse(localStorage.getItem("authUser"));


    await axios.get(`https://getjob.stechomeyazilim.info:5101/getSearchSUBDOMAINMega2Extra/select/${authData.ID}`)
    .then((res) => {
      setAllMyApplyExtra(res.data)
    })
  }

  useEffect(() => {
    setWPList(wp);
  }, [wp]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders);
      setIsEdit(false);
    }

  }, [orders]);

  useEffect(() => {
    if (!isEmpty(wp) && !!isEdit) {
      setWPList(wp);
      setIsEdit(false);
    }
  }, [wp]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };


  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteOrder = () => {
    if (order && order.ID) {
      dispatch(onDeleteOrder(order.ID));
      setDeleteModal(false);
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'ATÖLYE',
        accessor: 'Teacher_Session.Course_Schedules.Workshop.Workshop_Text',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return (
            cellProps.value != null ? <Name {...cellProps} /> : null)
        }
      },
      {
        Header: 'BAŞLIK',
        accessor: 'Teacher_Session.Course_Schedules.Workshop_Sessions.Workshop_Sessions_Text',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return (
            cellProps.value != null ? <Name {...cellProps} /> : null)
        }
      },
      {
        Header: "Günler",
        // accessor: "name",
        accessor: 'Teacher_Session.Course_Schedules.Course_Schedules_Day',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return(
            
            cellProps.row.original.Teacher_Session.Course_Schedules.Course_SheduledDay != null ? 
            cellProps.row.original.Teacher_Session.Course_Schedules.Course_SheduledDay.length > 0 ? 
            cellProps.row.original.Teacher_Session.Course_Schedules.Course_SheduledDay.map((item, index) => (
              <h4
              key={index}
            className="text-success text-center">
         {item.Sheduled_DayTitle}  / {item.StartTime} - {item.FinishTime}
          </h4>

          
           ))
            :
            null : null)
        }
      },

      {
        Header: "Başvuru Yapılan Kişinin Adı Soyadı",
        // accessor: "name",
        accessor: 'Users.NameSurname',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
    
     
      {        Header: 'Başvuru Tarihi',
        accessor: 'Created_DateTime',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },
  
    ],
    []
  );



  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Yönetim" breadcrumbItem="Başvurularım" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>

                <h3 className="text-danger fw-bold pt-10">Asıl Kontenjan</h3>


                {map((getWPList), (item, i) => {
                  let filterData = getAllMyApply.filter((x)=> x.Teacher_Session.Course_Schedules.Workshop_Sessions.SUBDOMAIN.ID == item.ID)
                  return(
                    <div className="">
                      { filterData.length > 0 ?  <a className="text-body fw-bold">{item.SUBDOMAIN_Title}</a> : null }
                     { filterData.length == 0 ?  null :
                      <TableContainer
                      columns={columns}
                      data={filterData}
                      isGlobalFilter={true}
                      isAddReferances={true}
                      handleOrderClicks={handleOrderClicks}
                      customPageSize={10}
                    />
                    }
                   
                   </div>
                )})}


<h3 className="text-danger fw-bold pt-10">Ek Kontenjan</h3>


{map((getWPList), (item, i) => {
                  let filterData = getAllMyApplyExtra.filter((x)=> x.Teacher_Session.Course_Schedules.Workshop_Sessions.SUBDOMAIN.ID == item.ID)
                  return(
                    <div className="margin-10 mt-10">
                     
                     { filterData.length > 0 ?  <a className="text-body fw-bold">{item.SUBDOMAIN_Title}</a> : null }
                     { filterData.length == 0 ? null :
                      <TableContainer
                      columns={columns}
                      data={filterData}
                      isGlobalFilter={true}
                      isAddReferances={true}
                      handleOrderClicks={handleOrderClicks}
                      customPageSize={10}
                    />
                    }
                   
                   </div>
                )})}
              
                </CardBody>
              </Card>
            </Col>
          </Row>
      
        </div>
      </div>
    </React.Fragment>
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;