import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  GET_REFERANCES_SUCCESS,
  GET_WORKSHOP2_SUCCESS,
  GET_POPUP_SUCCESS,
  UPDATE_POPUP_SUCCESS,
  UPDATE_SUBDOMAIN_SUCCESS,
  UPDATE_SUCCESS_COURSESCHEDULES,
  UPDATE_WORKSHOPSESSION_SUCCESS,
  UPDATE_SUCCESS_TEACHER,
  UPDATE_SUCCESS_SLIDER,
  UPDATE_SUCCESS_NEWS,
  UPDATE_SUCCESS_MEGA,
  UPDATE_WORKSHOP2_SUCCESS,
  UPDATE_SUCCESS_GALLERY,

  GET_WORKSHOP_SUCCESS,
  GET_WORKSHOPSESSION_SUCCESS,
  GET_APPLICATION_SUCCESS,
  GET_SLIDER_SUCCESS,
  GET_PROJECTS_SUCCESS,
  GET_NEWS_SUCCESS,
  GET_MEGA_SUCCESS,
  GET_TEACHER_SUCCESS,
  GET_DYNAMIC_SUCCESS,
  GET_CONTACT,
  GET_AUTH_SUCCESS,
  GET_AUTHUPDATE_SUCCESS,
  UPDATE_SUCCESS_PROJECT,
  GET_GALLERY_SUCCESS,
  ADD_REFERANCES_SUCCESS,
  UPDATE_REFERANCES_SUCCESS,
  GET_SUBPROJECT_SUCCESS,
  ADD_APPLY_SUCCESS,
  GET_SUCCESS_ATTENDANCE,
  GET_DYNAMICEXTRAFORM_SUCCESS,
  GET_FORM_SUCCESS,
  GET_ACTIVE_WORKSHOP_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  userProfile: {},
  error: {},
  popup: [],
  workshop: [],
  workshopSession: [],
  workshopsessionMega: [],
  slider: [],
  projects: [],
  news:[],
  mega:[],
  teacher:[],
  dynamic:[],
  contact:[],
  authlist:[],
  workshop2:[],
  gallery:[],
  referances:[],
  subProject:[],
  apply:[],
  attendance:[],
  dynamicFormExtra:[],
  form:[],
  workshopApply:[]
}

const contacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case GET_ACTIVE_WORKSHOP_SUCCESS:
      return {
        ...state,
        workshopApply: action.payload,
      }

    case GET_FORM_SUCCESS:
      return {
        ...state,
        form: action.payload,
      }

    case GET_DYNAMICEXTRAFORM_SUCCESS:
      return {
        ...state,
        dynamicFormExtra: action.payload,
      }


    case GET_SUCCESS_ATTENDANCE:
      return {
        ...state,
        attendance: action.payload,
      }

    case ADD_APPLY_SUCCESS:
      return {
        ...state,
        apply: action.payload,
      }

    case GET_SUBPROJECT_SUCCESS:
      return {
        ...state,
        subProject: action.payload,
      }

    case UPDATE_REFERANCES_SUCCESS:
      return {
        ...state,
        referances: state.referances.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case GET_REFERANCES_SUCCESS:
      return {
        ...state,
        referances: action.payload,
      }
         
    case GET_AUTHUPDATE_SUCCESS:
      return {
        ...state,
        authlist: [],
      }

    case GET_AUTH_SUCCESS:
      return {
        ...state,
        authlist: action.payload,
      }

      
      case GET_WORKSHOP2_SUCCESS:
        return {
          ...state,
          workshop2: action.payload,
        }

    case GET_CONTACT:
      return {
        ...state,
        contact: action.payload,
      }

    case GET_DYNAMIC_SUCCESS:
      return {
        ...state,
        dynamic: action.payload,
      }

    case GET_TEACHER_SUCCESS:
      return {
        ...state,
        teacher: action.payload,
      }
    case GET_MEGA_SUCCESS:
      return {
        ...state,
        mega: action.payload,
      }

    case GET_NEWS_SUCCESS:
      return {
        ...state,
        news: action.payload,
      }

    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      }

    case GET_SLIDER_SUCCESS:
      return {
        ...state,
        slider: action.payload,
      }
    case GET_APPLICATION_SUCCESS:
      return {
        ...state,
        workshopsessionMega: action.payload,
      }
    case GET_WORKSHOPSESSION_SUCCESS:
      return {
        ...state,
        workshopSession: action.payload,
      }
    case GET_WORKSHOP_SUCCESS:
      return {
        ...state,
        workshop: action.payload,
      }

      case GET_GALLERY_SUCCESS:
        return {
          ...state,
          gallery: action.payload,
        }

        
        case UPDATE_SUCCESS_GALLERY:
          console.log("deneme123",action.payload)
          return {
            ...state,
            gallery: state.gallery.map(x =>
              x.ID == action.payload.ID
                ? { x, ...action.payload }
                : x
            ),
          }
      
          
      case UPDATE_SUCCESS_MEGA:
        console.log("deneme123",action.payload)
        return {
          ...state,
          mega: state.mega.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }
      
        
                
      case UPDATE_WORKSHOP2_SUCCESS:
        return {
          ...state,
          workshop2: state.workshop2.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }

      case UPDATE_SUCCESS_NEWS:
        return {
          ...state,
          news: state.news.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }


      case UPDATE_SUCCESS_PROJECT:
        return {
          ...state,
          projects: state.projects.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }

       case UPDATE_SUCCESS_SLIDER:
        return {
          ...state,
          slider: state.slider.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }

      case UPDATE_SUBDOMAIN_SUCCESS:
        return {
          ...state,
          workshop: state.workshop.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }

        
        case UPDATE_SUCCESS_TEACHER:
          return {
            ...state,
            teacher: state.teacher.map(x =>
              x.ID == action.payload.ID
                ? { x, ...action.payload }
                : x
            ),
          }


        case UPDATE_WORKSHOPSESSION_SUCCESS:
          return {
            ...state,
            workshopsessionMega: state.workshopsessionMega.map(x =>
              x.ID == action.payload.ID
                ? { x, ...action.payload }
                : x
            ),
          }

        case UPDATE_SUCCESS_COURSESCHEDULES:
          console.log("lksdnfsd",state.workshopSession)
          return {
            ...state,
            workshopSession: state.workshopSession.map(x =>
              x.ID == action.payload.ID
                ? { x, ...action.payload }
                : x
            ),
          }

     case UPDATE_POPUP_SUCCESS:
        return {
          ...state,
          popup:  state.popup.map(x =>
            x.ID.toString() === action.payload.ID.toString()
              ? { x, ...action.payload }
              : x
          ),
        }
        
    case GET_POPUP_SUCCESS:
      return {
        ...state,
        popup:  action.payload,
      }

    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    
      case ADD_REFERANCES_SUCCESS:
      return {
        ...state,
        referances: [...state.referances, action.payload],
      }


    case ADD_USER_SUCCESS:

      return {
        ...state,
        users: [...state.users, action.payload],
      }

    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
      }

      case UPDATE_USER_SUCCESS:
        return {
          ...state,
          users: state.users.map(user =>
            user.ID.toString() === action.payload.ID.toString()
              ? { user, ...action.payload }
              : user
          ),
        }
  
      case UPDATE_USER_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
      case DELETE_USER_SUCCESS:
        return {
          ...state,
          users: state.users.filter(
            user => user.id.toString() !== action.payload.toString()
          ),
        }
  
      case DELETE_USER_FAIL:
        return {
          ...state,
          error: action.payload,
        }

    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default contacts
