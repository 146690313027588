import PropTypes from "prop-types";
import React, { useState } from "react";

import {Alert, Row, Col, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, socialLogin } from "../../store/actions";
import CarouselPage from "./CarouselPage";


const Login = props => {
  const [passwordShow, setPasswordShow] = useState(false);

  const [getMail, setMail] = useState(false);
  const [getPassword, setPassword] = useState(false);
  const [getRememberme, setRememberMe] = useState(false);


  //meta title
  document.title = "Giriş | MEGA - Öğrenci Paneli";

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: getMail != null ? getMail : '',
      password: getPassword != null ? getPassword : '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Lütfen Telefon Numaranızı Giriniz"),
      password: Yup.string().required("Lütfen Şifrenizi Giriniz"),
    }),
    onSubmit: (values) => {
      if(getRememberme == true){

      localStorage.setItem("rememberMail",values.email);
      localStorage.setItem("rememberPassword",values.password);
      localStorage.setItem("rememberCheck", getRememberme);

      }

      console.log("values",values)
      dispatch(loginUser(values, props.router.navigate));
    }
  });

  function _changeRemember(check) {
    setRememberMe(check)
  }
  

  React.useEffect(() => {

    let rememberMe = localStorage.getItem("rememberCheck");
    if(rememberMe != null){

    if(rememberMe =="true"){
      let mailAdress = localStorage.getItem("rememberMail");
      let password1 = localStorage.getItem("rememberPassword");
    
      setRememberMe(true)
      setMail(mailAdress)
      setPassword(password1)
    }else{
      setRememberMe(true)

     // setIsActive(true)

    }      
  }
  }, []);

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));



    const signIn = type => {
        dispatch(socialLogin(type, props.router.navigate));
    };

   

  //for facebook and google authentication
  const socialResponse = type => {
    signIn(type);
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    
                    <div className="">
                      <div>
                        <h5 className="text-primary">Hoşgeldiniz!</h5>
                        <p className="text-muted">
                         Meram Gelişim Akademisi
                        </p>
                      </div>

                      <div className="mt-4">
                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                    {error ? <Alert color="danger">{error}</Alert> : null}

                          <div className="mb-3">
                            <Label className="form-label">Telefon Numaranız</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Telefon numaranızı giriniz"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3 ">
                          {/*   <div className="float-end">
                              <Link to="/auth-recoverpw-2" className="text-muted">Şifremi Unuttum?</Link>
                            </div>*/}
                            <Label className="form-label">Şifreniz</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                value={validation.values.password || ""}
                                type={passwordShow ? "text" : "password"}
                                placeholder="Şifrenizi Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password && validation.errors.password ? true : false
                                }
                              />
                              <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i></button>
                            </div>
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="auth-remember-check"
                             
                              checked={getRememberme}
                              value={getRememberme}
                              onChange={(val) =>
                                _changeRemember(val.target.checked)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="auth-remember-check"
                            >
                              Beni Hatırla
                            </label>

                            <div className="float-end">
                              <Link to="/forgot-password" className="text-muted">Şifremi Unuttum?</Link>
                            </div>
                            
                          </div>


                      
                          <div className="mt-3">
                            <div className="float-end ">
                            <Link
                              to="/pages-register-2"
                              className=" float-end btn btn-secondary btn-block "
                            >
                              Kayıt Ol
                            </Link>

                           
                            </div>

                            <button
                              className=" btn btn-primary btn-block "
                              type="submit"
                            >
                              Giriş Yap
                            </button>

                          </div>
                        </Form>

                      
                      </div>
                    </div>

                  
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
