import React from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/logo-light.png"

const WelcomeComp = () => {
  let getData = localStorage.getItem("authUser");

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Hoşgeldiniz !</h5>
                <p>Öğrenci Paneli</p>
              </div>
            </Col>
            
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-xl profile-user-wid ">
              <img
                  className="img-thumbnail "
                  src={profileImg} />

              </div>
              <h5 className="font-size-15 text-truncate">{ localStorage.getItem("authUser") != null ? JSON.parse(getData).NameSurname : null}</h5>
              <p className="text-muted mb-0 text-truncate">{ localStorage.getItem("authUser") != null ? JSON.parse(getData).GsmNumber : null}</p>
            </Col>

           
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
