import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader';
import {Base64} from 'js-base64';

import {
  deleteOrder as onDeleteOrder,
} from "store/actions";

import {
  addNews as onAddNewNews,
  updateNews as onUpdateNews,
  getNews as onGetNews,
} from "store/contacts/actions";

import {
  Name,
  Status,
  Date
}
  from "./EcommerceOrderCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import moment from "moment";

function EcommerceOrder() {

  //meta title
  document.title = "Haberler | Mega - Admin Paneli";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [getHtmlData, setHtmlData] = useState();

  
  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [getEditNews, setEditNews] = useState(null);
  const [getIsActive, setIsActive] = useState(false);

  const [getFileName, setFileName] = useState(null);

  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }
   
  // handle the status of the file upload
  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      newsTitle: (getEditNews && getEditNews.News_Title) || '',
      //newsPhoto: (getEditNews && getEditNews.News_PhotoLink) || ''
      newsShortExp: (getEditNews && getEditNews.News_ShortExplanation) || '',
     },
    validationSchema: Yup.object({
      newsTitle: Yup.string().required("Haber başlığını giriniz"),
      newsShortExp: Yup.string().required("Haber kısa açıklama giriniz")
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateOrder = {
          ID: order ? order.ID : 0,
          News_Title: values.newsTitle,
         // News_PhotoLink: values.newsPhoto,
          News_IsActive: getIsActive,
          News_Explanation:  Base64.encode(getHtmlData),
          News_ShortExplanation: values.newsShortExp,
        };
        // update order
        dispatch(onUpdateNews(updateOrder));
        validation.resetForm();
      } else {
        var testDateUtc = moment.utc(new Date());       
        var localDate = moment(testDateUtc).local();

        console.log("localDate123",localDate)
        const newOrder = {
          News_Title: values["newsTitle"],
          News_PhotoLink:getFileName,
          News_IsActive: getIsActive,
          News_CreteadDateTime:localDate,
          News_Explanation: getHtmlData,
          News_ShortExplanation: values["newsShortExp"],
        };
        // save new order
        dispatch(onAddNewNews(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();
  const { users } = useSelector(state => ({
    users: state.contacts.news,
  }));

  useEffect(() => {
   if (users && !users.length) {
      dispatch(onGetNews());
      setIsEdit(false);
   }
  }, [dispatch, users]);

  useEffect(() => {
    setOrderList(users);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setOrderList(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = arg => {
    const order = arg;
    setEditNews(order)
    setOrder(order);
    setHtmlData(Base64.decode(order.News_Explanation))
    setIsEdit(true);

    toggle();
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.id) {
      dispatch(onDeleteOrder(order.id));
      setDeleteModal(false);
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Haber Görseli",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.News_PhotoLink ? (
              <div className="avatar-xl">
                {cellProps.News_Title != null ?  <span className="avatar-title rounded-circle">
                  {cellProps.News_Title.charAt(0)}
                </span>  : null }
              </div>
            ) : (
              <div>
                <img
                  className="avatar-xl"
                  src={'http://megasubdomain.stechomeyazilim.info/' + cellProps.News_PhotoLink}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Haber Başlığı",
        accessor: "News_Title",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Eklenme Tarihi",
        disableFilters: true,
        accessor: "News_CreteadDateTime",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: 'Aktiflik Durumu',
        disableFilters: true,
        accessor: 'News_IsActive',
        filterable: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },
      {
        Header: 'İşlemler',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleOrderClick(orderData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Haberler" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddNews={true}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Haber Düzenle" : "Haber Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                        
                    <div className="mb-3">
                      <Label className="form-label">Başlık</Label>
                      <Input
                        name="newsTitle"
                        type="text"
                        placeholder="Başlık"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.newsTitle || ""}
                        invalid={
                          validation.touched.newsTitle && validation.errors.newsTitle ? true : false
                        }
                      />
                      {validation.touched.newsTitle && validation.errors.newsTitle ? (
                        <FormFeedback type="invalid">{validation.errors.newsTitle}</FormFeedback>
                      ) : null}
                    </div>
                   
                    <div className="mb-3">
                      <Label className="form-label">Kısa Açıklama</Label>
                      <Input
                        name="newsShortExp"
                        type="text"
                        placeholder="Kısa Açıklama"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.newsShortExp || ""}
                        invalid={
                          validation.touched.newsShortExp && validation.errors.newsShortExp ? true : false
                        }
                      />
                      {validation.touched.newsShortExp && validation.errors.newsShortExp ? (
                        <FormFeedback type="invalid">{validation.errors.newsShortExp}</FormFeedback>
                      ) : null}
                    </div>
                    
                    <div className="mb-3">
                    <Label className="form-label">Haber Detay</Label>
                    <Form method="post">
                    <CKEditor
                      editor={ClassicEditor}
                      data={getHtmlData}
                      
                      onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                      }}
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                     //   console.log( { event, editor, data } );
                        setHtmlData(data)
                    } }
                    />
                  </Form>
                  </div>
                
               
                        <Row>
                <Col xs={12} className="mb-3">
                            <label htmlFor="resume">Dosya(800*600)</label>
                         <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          styles={{
            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
            inputLabelWithFiles: { margin: '20px 3%' }
          }}
          
          canRemove={false}
          PreviewComponent={Preview}
          accept="image/*,audio/*,video/*"
        />
                
                          </Col>
                </Row>

                <div className="d-flex">
                          <div className="square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                        
                       
                        </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Kaydet
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;