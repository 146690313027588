import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
  deleteOrder as onDeleteOrder,
} from "store/actions";

import {
  addAppSetting as onAddWorkshopSession,
  updateWorkshopSession as onUpdateWorkshopSession,
  getWorkshopSessionMega as onGetWorkshopSessionMega,
} from "store/contacts/actions";


import {
  OrderId,
  BillingName,
  Date,
  StatusMeram,
  StatusActive
}
  from "./EcommerceOrderCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import axios from "axios";

function EcommerceOrder() {
  const [getSUBDOMAINData, setSUBDOMAINData] = useState([]);

  //meta title
  document.title = "Başvuru Ayarları | MEGA - Admin Paneli";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);

  const [getIsMeram, setIsMeram] = useState(null);
  const [getActive, setActive] = useState(null);

  const [getSUBDOMAINText, setSUBDOMAINText] = useState(null);

  const [editAppSetting, seteditAppSetting] = useState(null);

  const [getChoiceSUBDOMAIN, setChoiceSUBDOMAIN] = useState(null);

  

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (editAppSetting && editAppSetting.Workshop_Sessions_Text) || '',
      startage: (editAppSetting && editAppSetting.StartAge) || '',
      finishage: (editAppSetting && editAppSetting.FinishAge) || '',
      paymentStatus: (editAppSetting && editAppSetting.IsMeram) || '',
    },
    validationSchema: Yup.object({

      name: Yup.string().required("Lütfen başlığı giriniz"),
      startage: Yup.string().required("Lütfen başlangıç yaşını seçiniz"),
      finishage: Yup.string().required("Lütfen bitiş yaşını seçiniz"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateOrder = {
          ID: order ? order.ID : 0,
          Workshop_Sessions_Text: values.name,  
          StartAge: values.startage,
          FinishAge: values.finishage,
          IsMeram: getIsMeram,
          SUBDOMAIN_ID:getChoiceSUBDOMAIN,
          IsActive:getActive
        };
        // update order
        dispatch(onGetWorkshopSessionMega());
        dispatch(onUpdateWorkshopSession(updateOrder));
        validation.resetForm();
      } else {
        const newOrder = {
          Workshop_Sessions_Text: values["name"],
          StartAge: values["startage"],
          FinishAge: values["finishage"],
          IsMeram: getIsMeram,
          SUBDOMAIN_ID:getChoiceSUBDOMAIN,
          IsActive:getActive
        };
        // save new order
        dispatch(onAddWorkshopSession(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();
  const { orders } = useSelector(state => ({
    orders: state.contacts.workshopsessionMega,
  }));

  
  const _setChoiceSUBDOMAIN = arg => {
    setChoiceSUBDOMAIN(arg.target.value)
    console.log("lk123kl",arg.target.value)
   }

  const _getWorkshop= async () => {
  
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getSUBDOMAINMega/select/`)
    .then((res) => {
      setSUBDOMAINData(res.data)
    })
  }

  useEffect(() => {
    _getWorkshop()
    if (orders && !orders.length) {
      dispatch(onGetWorkshopSessionMega());
      setIsEdit(false);
    }
  }, [dispatch, orders]);

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders);
      setIsEdit(false);
    }
  }, [orders]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = arg => {
    const order = arg;
    seteditAppSetting(order)
    setOrder(order);

    setIsEdit(true);
    setIsMeram(order.IsMeram)
    setActive(order.IsActive)
    setSUBDOMAINText(order.SUBDOMAIN.SUBDOMAIN_Title)
    setChoiceSUBDOMAIN(order.SUBDOMAIN_ID)
    toggle();
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.id) {
      dispatch(onDeleteOrder(order.id));
      setDeleteModal(false);
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [

      {
        Header: 'Başlık',
        accessor: 'Workshop_Sessions_Text',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <OrderId {...cellProps} />;
        }
      },
      {
        Header: 'Bölüm',
        accessor: 'SUBDOMAIN.SUBDOMAIN_Title',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        }
      },
      {
        Header: 'Yaş Aralığı',
        accessor: 'StartAge',
        filterable: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },
      {
        Header: 'Meramda Oturan',
        accessor: 'IsMeram',
        disableFilters:true,
        filterable: true,
        Cell: (cellProps) => {
          return <StatusMeram {...cellProps} />;
        }
      },
      {
        Header: 'Durum',
        accessor: 'IsActive',
        disableFilters:true,
        Cell: (cellProps) => {
          return <StatusActive {...cellProps} />;
        }
      },
      {
        Header: 'Detay',
        accessor: 'view',
        disableFilters: true,
        Cell: () => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={toggleViewModal}
            >
              Detay
            </Button>);
        }
      },
      {
        Header: 'İşlemler',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleOrderClick(orderData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Yönetim" breadcrumbItem="Başvuru Ayarları" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={true}
                    isAddApplication={true}
                    handleUserClick={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Başvuru Düzenle" : "Başvuru Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Başlık</Label>
                      <Input
                        name="name"
                        type="text"
                        placeholder="Başlık Giriniz"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name ? true : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="d-flex">
                    <div className="mb-3">
                      <Label className="form-label">Başlangıç Yaşı</Label>
                      <Input
                        name="startage"
                        type="number"
                        placeholder="Başlangıç Yaşını Giriniz"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.startage || ""}
                        invalid={
                          validation.touched.startage && validation.errors.startage ? true : false
                        }
                      />
                      {validation.touched.startage && validation.errors.startage ? (
                        <FormFeedback type="invalid">{validation.errors.startage}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Bitiş Yaşı</Label>
                      <Input
                        name="finishage"
                        type="number"
                        placeholder="Bitiş Yaşını Giriniz"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.finishage || ""}
                        invalid={
                          validation.touched.finishage && validation.errors.finishage ? true : false
                        }
                      />
                      {validation.touched.finishage && validation.errors.finishage ? (
                        <FormFeedback type="invalid">{validation.errors.finishage}</FormFeedback>
                      ) : null}
                    </div>
                    </div>
                  
                    <div className="d-flex">
                    <Label className="form-label">Meramda Otursun</Label>

                          <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsMeram}
                              onChange={() =>
                                setIsMeram(!getIsMeram)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>

                          <Label className="form-label">Aktiflik Durumu</Label>

                          <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch2"
                              className="switch switch-info"
                              defaultChecked={getActive}
                              onChange={() =>
                                setActive(!getActive)
                              }
                            />
                            <label
                              htmlFor="square-switch2"
                              data-on-label="Aktif"
                              data-off-label="Pasif"
                            />
                          </div>
                       
                        </div>
                        
                        <div className="mb-3">
                      <Label className="form-label">W Seçiniz</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={(text) => _setChoiceSUBDOMAIN(text)}
                        value={
                          getSUBDOMAINText
                        }>
                        {getSUBDOMAINData.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.SUBDOMAIN_Title}</option>
                        ))}
                       
                      </Input>
                      {validation.touched.wsText && validation.errors.wsText ? (
                        <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                      ) : null}
                    </div>
                   
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Kaydet
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;