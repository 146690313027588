import React, { useState,useEffect } from "react";
import { FormFeedback,Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";


import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link,useLocation } from "react-router-dom";

import {
  getDynamicFormExtra as onGetDynamicFormExtra
} from "store/contacts/actions";

import { useSelector, useDispatch } from "react-redux";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import moment from "moment";
import axios from "axios";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useNavigate } from "react-router-dom";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader';
import img from "../../assets/images/logo.png";
import AllUniversity from './province-universities.json';
import  AllProgram  from './University_Department.json';

import SelectOption from "./SelectOption";
import { useFormik } from "formik";
import * as Yup from "yup";

const FormAdvanced = () => {

  const navigate = useNavigate();

  const location = useLocation();
  const state = location.state;

  
  const [allUniversity, setAllUniversity] = useState(AllUniversity);

  
  const { users } = useSelector(state => ({
    users: state.contacts.dynamicFormExtra,
  }));


  const getUploadParams = ({ file }) => {
    try {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  } catch (err) {
    return showToast(false,"Dosya Yüklenirken Bir Hata Oluştu, Lütfen Tekrardan Yükleyiniz!")    

    }
  }
   
  const handleChangeStatus = ({ xhr }, type) => {
    try {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {

  if(xhr.response.length >0 ) {


  const result = JSON.parse(xhr.response);

    let filterData = getFileName.filter((x)=> x.type == type)
    if(filterData.length>0){
      let newMarkers = getFileName.map(el => (
        el.type===type  ? {...el,
          value :result.file.filename, 
          type : type
        }: el
    ))

    setFileName(newMarkers)
    }else{


      setFileName(oldArray => [...oldArray,{value :result.file.filename, type : type,fileType : result.file.mimetype}] )
    }
  } else{

    showToast(false,"Dosya Yüklenirken Bir Hata Oluştu, Lütfen Tekrardan Yükleyiniz!")    
  }

        }
      }
    }
  } catch (err) {
     showToast(false,"Dosya Yüklenirken Bir Hata Oluştu, Lütfen Tekrardan Yükleyiniz!")    

    }
  }
  const dispatch = useDispatch();

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  useEffect(() => {

    if (users && !users.length) {
      dispatch(onGetDynamicFormExtra(state._item.ID))

      state._item.DynamicForm_Success.filter((x)=>x.Dynamic_Form_Extra.DynamicType !=  "Dosya Yükleme Alanı").map(async(item, index) => {
        setTextChange(oldArray => [...oldArray,{value :item.DynamicForm_Success_Value, type : item.Dynamic_Form_Extra.Title}] )
      })

      let ibanCheck = state._item.DynamicForm_Success.filter((x)=>x.Dynamic_Form_Extra.DynamicType ==  "Iban Alanı").length
    if(ibanCheck > 0){
      state._item.DynamicForm_Success.filter((x)=>x.Dynamic_Form_Extra.DynamicType ==  "Iban Alanı").map(async(item, index) => {
        validation.setFieldValue('ibanNumber', item.DynamicForm_Success_Value)
      })
    }else{
      validation.setFieldValue('ibanNumber', "")

    }
      
     

    
      state._item.DynamicForm_Success.filter((x)=>x.Dynamic_Form_Extra.DynamicType ==  "Dosya Yükleme Alanı").map(async(item, index) => {
        setFileName(oldArray => [...oldArray,{value :item.DynamicForm_Success_Value, type : item.Dynamic_Form_Extra.Title , fileType : item.DynamicForm_Success_DataType}] )
    
      })
   }
  
  }, [dispatch, users]);

  const [getData, setData] = useState([]);

  const postForm = async(values) => {
   let filterDataMultiSelect = getData.filter((x)=> x.DynamicType == "Çoklu Seçim Aracı")
   let filterDataDateSelect = getData.filter((x)=> x.DynamicType == "Tarih Aracı")
   let filterDataListSelect = getData.filter((x)=> x.DynamicType == "Listeli Seçim")
   let filterDataPassword = getData.filter((x)=> x.DynamicType == "Şifre Alanı")
   let filterDataListShow = getData.filter((x)=> x.DynamicType == "Listeli Gösterim")
   let filterDataLong = getData.filter((x)=> x.DynamicType == "Uzun Yazı Alanı")
   let filterDataShort = getData.filter((x)=> x.DynamicType == "Yazı Alanı")
   let filterDataAddDocument = getData.filter((x)=> x.DynamicType == "Dosya Yükleme Alanı")
   let filterDataNumber = getData.filter((x)=> x.DynamicType == "Sayı Alanı")

   let filterDataCity = getData.filter((x)=> x.DynamicType == "İl Alanı")
   let filterDataUniversity = getData.filter((x)=> x.DynamicType == "Üniversite Alanı")
   let filterDataProgram = getData.filter((x)=> x.DynamicType == "Program Alanı")
   let filterDataIban = getData.filter((x)=> x.DynamicType == "Iban Alanı")

   let filterDataCinsiyet = getData.filter((x)=> x.DynamicType == "Cinsiyet Seçimi")
   let filterDataTC = getData.filter((x)=> x.DynamicType == "TC Alanı")
   let filterDataAd = getData.filter((x)=> x.DynamicType == "Ad Soyad Alanı")
   let filterDataAdres = getData.filter((x)=> x.DynamicType == "Adres Alanı")


    showToast(true)    
    let IDuser = localStorage.getItem("authUser") != null ? JSON.parse(localStorage.getItem("authUser")).ID : null

    axios.delete(`https://getjob.stechomeyazilim.info:5101/deleteDynamicFormSuccess/${state._item.ID}/${IDuser}`)
    var localDate = moment().local();

    let user = {
      Update_DateTime:localDate
    }

    await axios.patch(`https://getjob.stechomeyazilim.info:5101/patchDynamicFormSuccessUserMega/${state._item.DynamicForm_Success_User[0].ID}`, user)
    .then(async (res1) => {
    
    })

    
    if(getTextChange.length > 0){

      getTextChange.map(async(item, index) => {

        if(item.type == "Cinsiyet Seçiniz"){
          let dataCinsiyet = {
          Users_ID:authData.ID,
          Created_DateTime:localDate,
          DynamicForm_Success_Value:item.value,
          Dynamic_Form_Extra_ID:filterDataCinsiyet[0].ID,
          Dynamic_Form_ID:state._item.ID,
          DynamicForm_Success_User_ID:state._item.DynamicForm_Success_User[0].ID,
        }

        await axios.post(`https://getjob.stechomeyazilim.info:5101/addDynamicFormSuccessMega/send`, dataCinsiyet).then(async(res) => {
    
        })
      }

      if(item.type == "ADRES"){
        let dataCinsiyet = {
        Users_ID:authData.ID,
        Created_DateTime:localDate,
        DynamicForm_Success_Value:item.value,
        Dynamic_Form_Extra_ID:filterDataAdres[0].ID,
        Dynamic_Form_ID:state._item.ID,
        DynamicForm_Success_User_ID:state._item.DynamicForm_Success_User[0].ID
      }
      
      await axios.post(`https://getjob.stechomeyazilim.info:5101/addDynamicFormSuccessMega/send`, dataCinsiyet).then(async(res) => {
  
      })
    }

      
      if(item.type == "AD SOYAD"){
        let dataAd = {
          Users_ID:authData.ID,
          Created_DateTime:localDate,
          DynamicForm_Success_Value:item.value,
          Dynamic_Form_Extra_ID:filterDataAd[0].ID,
          Dynamic_Form_ID:state._item.ID,
          DynamicForm_Success_User_ID:state._item.DynamicForm_Success_User[0].ID
        }
        
        await axios.post(`https://getjob.stechomeyazilim.info:5101/addDynamicFormSuccessMega/send`, dataAd).then(async(res) => {
    
        })
      }

        if(item.type == "TC KİMLİK NUMARASI"){

        let dataTc = {
          Users_ID:authData.ID,
          Created_DateTime:localDate,
          DynamicForm_Success_Value:item.value,
          Dynamic_Form_Extra_ID:filterDataTC[0].ID,
          Dynamic_Form_ID:state._item.ID,
          DynamicForm_Success_User_ID:state._item.DynamicForm_Success_User[0].ID
        }
        
        await axios.post(`https://getjob.stechomeyazilim.info:5101/addDynamicFormSuccessMega/send`, dataTc).then(async(res) => {
    
        })
      }
  
      })
    }

   /* if(getTextChange.length > 0){
      getTextChange.map(async(item, index) => {
        let filterDataProgram = getData.filter((x)=> x.Title == item.type)

        let data = {
          Users_ID:authData.ID,
          Created_DateTime:localDate,
          DynamicForm_Success_Value:item.value,
          Dynamic_Form_Extra_ID:filterDataProgram[0].ID,
          Dynamic_Form_ID:state._item.ID,
          DynamicForm_Success_User_ID:state._item.DynamicForm_Success_User[0].ID
        }
        
        await axios.post(`https://getjob.stechomeyazilim.info:5101/addDynamicFormSuccessMega/send`, data).then(async(res) => {
    
        })
  
      })
    } */

    if(selectedMulti.length > 0){
      selectedMulti.map(async(item, index) => {
        let data = {
          Users_ID:authData.ID,
          Created_DateTime:localDate,
          DynamicForm_Success_Value:item.label,
          Dynamic_Form_Extra_ID:filterDataMultiSelect[0].ID,
          Dynamic_Form_ID:state._item.ID,
          DynamicForm_Success_User_ID:state._item.DynamicForm_Success_User[0].ID
        }
        
        await axios.post(`https://getjob.stechomeyazilim.info:5101/addDynamicFormSuccessMega/send`, data).then(async(res) => {
    
        })
  
      })
    }

    if(values.ibanNumber != null) {
      let dataDate = {
        Users_ID:authData.ID,
        Created_DateTime:localDate,
        DynamicForm_Success_Value:values.ibanNumber,
        Dynamic_Form_Extra_ID:filterDataIban[0].ID,
        Dynamic_Form_ID:state._item.ID,
        DynamicForm_Success_User_ID:state._item.DynamicForm_Success_User[0].ID
      }
      
      await axios.post(`https://getjob.stechomeyazilim.info:5101/addDynamicFormSuccessMega/send`, dataDate).then(async(res) => {
  
      })
    }

    if(getFileName.length > 0) {
  
      getFileName.map(async(item, index) => {
        let filterDataProgram = getData.filter((x)=> x.Title == item.type)

        let data = {
          Users_ID:authData.ID,
          Created_DateTime:localDate,
          DynamicForm_Success_Value:item.value,
          Dynamic_Form_Extra_ID:filterDataProgram[0].ID,
          Dynamic_Form_ID:state._item.ID,
          DynamicForm_Success_User_ID:state._item.DynamicForm_Success_User[0].ID,
          DynamicForm_Success_DataType : item.fileType
        }
        
        await axios.post(`https://getjob.stechomeyazilim.info:5101/addDynamicFormSuccessMega/send`, data).then(async(res) => {
    
        })
  
      })

    }
  
   /* if(getTextDate != null) {

      let dataDate = {
        Users_ID:JSON.parse(authData).ID,
        Created_DateTime:localDate,
        DynamicForm_Success_Value:moment(getTextDate).format('YYYY-MM-DD'),
        Dynamic_Form_Extra_ID:filterDataDateSelect[0].Dynamic_Form_ID,
        Dynamic_Form_ID:state._item.ID
      }
      
      await axios.post(`https://getjob.stechomeyazilim.info:5101/addDynamicFormSuccessMega/send`, dataDate).then(async(res) => {
  
      console.log("resDate",res)
      })
    } */


    if(getTextPassword != null) {
  
      let dataDate = {
        Users_ID:authData.ID,
        Created_DateTime:localDate,
        DynamicForm_Success_Value:getTextPassword,
        Dynamic_Form_Extra_ID:filterDataPassword[0].ID,
        Dynamic_Form_ID:state._item.ID,
        DynamicForm_Success_User_ID:state._item.DynamicForm_Success_User[0].ID
      }
      
      await axios.post(`https://getjob.stechomeyazilim.info:5101/addDynamicFormSuccessMega/send`, dataDate).then(async(res) => {
  
      })
    }
    
    
    if(getselectcityNameEducation != null) {
      let dataDate = {
        Users_ID:authData.ID,
        Created_DateTime:localDate,
        DynamicForm_Success_Value:getselectcityNameEducation,
        Dynamic_Form_Extra_ID:filterDataCity[0].ID,
        Dynamic_Form_ID:state._item.ID,
        DynamicForm_Success_User_ID:state._item.DynamicForm_Success_User[0].ID
      }
      
      await axios.post(`https://getjob.stechomeyazilim.info:5101/addDynamicFormSuccessMega/send`, dataDate).then(async(res) => {
  
      })
    }

    if(getUniversitiesName != null) {
      let dataDate = {
        Users_ID:authData.ID,
        Created_DateTime:localDate,
        DynamicForm_Success_Value:getUniversitiesName,
        Dynamic_Form_Extra_ID:filterDataUniversity[0].ID,
        Dynamic_Form_ID:state._item.ID,
        DynamicForm_Success_User_ID:state._item.DynamicForm_Success_User[0].ID
      }
      
      await axios.post(`https://getjob.stechomeyazilim.info:5101/addDynamicFormSuccessMega/send`, dataDate).then(async(res) => {
  
      })
    }
    
    if(getDepartmantName != null) {
      let dataDate = {
        Users_ID:authData.ID,
        Created_DateTime:localDate,
        DynamicForm_Success_Value:getDepartmantName,
        Dynamic_Form_Extra_ID:filterDataProgram[0].ID,
        Dynamic_Form_ID:state._item.ID,
        DynamicForm_Success_User_ID:state._item.DynamicForm_Success_User[0].ID
      }
      
      await axios.post(`https://getjob.stechomeyazilim.info:5101/addDynamicFormSuccessMega/send`, dataDate).then(async(res) => {
  
      })
    }

    if(getTextAreaChangeLong != null) {
      console.log("getTextPassword",getTextAreaChangeLong)
  
      let dataDate = {
        Users_ID:authData.ID,
        Created_DateTime:localDate,
        DynamicForm_Success_Value:getTextAreaChangeLong,
        Dynamic_Form_Extra_ID:filterDataLong[0].ID,
        Dynamic_Form_ID:state._item.ID,
        DynamicForm_Success_User_ID:state._item.DynamicForm_Success_User[0].ID
      }
      
      await axios.post(`https://getjob.stechomeyazilim.info:5101/addDynamicFormSuccessMega/send`, dataDate).then(async(res) => {
  
      })
    }

    if(getTextAreaChangeIban != null) {
      console.log("getTextAreaChangeShort",getTextAreaChangeIban)
  
      let dataDate = {
        Users_ID:authData.ID,
        Created_DateTime:localDate,
        DynamicForm_Success_Value:getTextAreaChangeIban,
        Dynamic_Form_Extra_ID:filterDataShort[0].ID,
        Dynamic_Form_ID:state._item.ID,
        DynamicForm_Success_User_ID:state._item.DynamicForm_Success_User[0].ID
      }
      
      await axios.post(`https://getjob.stechomeyazilim.info:5101/addDynamicFormSuccessMega/send`, dataDate).then(async(res) => {
  
      })
    }

    if(getTextAreaChangePhone != null) {
      console.log("getTextAreaChangeShort",getTextAreaChangePhone)
  
      let dataDate = {
        Users_ID:authData.ID,
        Created_DateTime:localDate,
        DynamicForm_Success_Value:getTextAreaChangePhone,
        Dynamic_Form_Extra_ID:filterDataShort[0].ID,
        Dynamic_Form_ID:state._item.ID,
        DynamicForm_Success_User_ID:state._item.DynamicForm_Success_User[0].ID
      }
      
      await axios.post(`https://getjob.stechomeyazilim.info:5101/addDynamicFormSuccessMega/send`, dataDate).then(async(res) => {
  
      })
    }

    if(data_attr != 0) {

  
      let dataDate = {
        Users_ID:authData.ID,
        Created_DateTime:localDate,
        DynamicForm_Success_Value:(data_attr).toString(),
        Dynamic_Form_Extra_ID:filterDataNumber[0].ID,
        Dynamic_Form_ID:state._item.ID,
        DynamicForm_Success_User_ID:state._item.DynamicForm_Success_User[0].ID
      }
      
      await axios.post(`https://getjob.stechomeyazilim.info:5101/addDynamicFormSuccessMega/send`, dataDate).then(async(res) => {
  
      })
    }
    
  

      if(selectedGroup != null){
      let data = {
        Users_ID:authData.ID,
        Created_DateTime:localDate,
        DynamicForm_Success_Value:selectedGroup.label,
        Dynamic_Form_Extra_ID:filterDataListSelect[0].ID,
        Dynamic_Form_ID:state._item.ID,
        DynamicForm_Success_User_ID:state._item.DynamicForm_Success_User[0].ID
      }
      
      await axios.post(`https://getjob.stechomeyazilim.info:5101/addDynamicFormSuccessMega/send`, data).then(async(res) => {
  
      console.log("resMulti",res)
      })

    }


  
  }


  function showToast(type,getFalseDesc) {
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla formu doldurdunuz!"
    } else{
       ele = "error"
       message = getFalseDesc
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);

   
    if(type == true){
    navigate('/form', { state: {
      update: true
      } })
    }

  }


  useEffect(() => {
    setData(users);
  }, [users]);

  //meta title
  document.title = "Dinamik Form | MEGA - Admin Paneli"

  const [sq1, setsq1] = useState(true);
 
  //** Datepicker Method */

  const [pick_date, setPickDate] = useState("");


  const [textcount, settextcount] = useState(0);

  const [textareabadge, settextareabadge] = useState(0);

  const [data_attr, setdata_attr] = useState(0);


  const [selectedGroup, setselectedGroup] = useState(null);
  const [selectedMulti, setselectedMulti] = useState([]);


  const [getTextDate, setTextDate] = useState(null);
  const [getTextPassword, setTextPassword] = useState(null);
  const [getTextAreaChangeShort, setTextAreaChangeShort] = useState(null);
  const [getTextAreaChangeLong, setTextAreaChangeLong] = useState(null);
  const  [getCityList, setCityList] = useState([])
  const  [getListDiscrict, setListDiscrict] = useState([])
  const  [getUniversityList, setUniversityList] = useState([])

  const  [getUniversityDepartmant, setUniversityDepartmant] = useState([])

  
  const  [getselectcityNameEducation, setselectcityNameEducation] = useState(null);
  const  [getUniversitiesName, setUniversitiesName] = useState(null);
  const  [getDepartmantName, setDepartmantName] = useState(null);


  const  [getTextAreaChangePhone, setTextAreaChangePhone] = useState(null);
  const  [getTextAreaChangeIban, setTextAreaChangeIban] = useState(null);

  
  const  [getTextChange, setTextChange] = useState([]);
  const  [getFileName, setFileName] = useState([]);
  

  function textAreaChangeShort(event,type) {
 
    if(type=="ÖĞRENCİNİN KENDİSİNE AİT IBAN NUMARASI"){
      const count = event.target.value.length;

      if (count > 0) {
        settextareabadge(true);
      } if (count > 24) {
        settextareabadge(false);
      }

      settextcount(event.target.value.length);

    }
    let filterData = getTextChange.filter((x)=> x.type == type)
    if(filterData.length>0){

      let newMarkers = getTextChange.map(el => (
        el.type===type  ? {...el,
          value :event.target.value, 
          type : type
        }: el
    ))
      setTextChange(newMarkers)
    }else{

      setTextChange(oldArray => [...oldArray,{value :event.target.value, type : type}] )
    }
   
  }


  function textAreaChangeLong(event) { 
    const count = event.target.value.length;
  
    if (count > 0) {
      settextareabadge(true);
    } else {
      settextareabadge(false);
    }

    setTextAreaChangeLong(event.target.value);
  }

  function textPasswordChange(event) {   
    setTextPassword(event.target.value);
  }

  function handleChangeDate(event) {  
    console.log("lknsldkf",event) 
    setTextDate(event);
    setPickDate(event);
  }


  function handleSelectGroup(selectedGroup) {
    console.log("selectedGroup12553",selectedGroup)
    setselectedGroup(selectedGroup);
  }

  function handleMulti(selectedMulti) {
    console.log("selectedMulti123",selectedMulti)
    setselectedMulti(selectedMulti);
  }


  let authData = localStorage.getItem("authUser") != null ? JSON.parse(localStorage.getItem("authUser")) : null

  useEffect(() => {
    _getCity()
  }, []);


  const _getCity = async () => {
   
       await axios.get(`https://getjob.stechomeyazilim.info:5101/getCity/TR`)
       .then((res) => {
  
         let newCityList = res.data.sort(function (a, b) {
          var atitle = a.name;
          var btitle = b.name;
          var alfabe = "0123456789AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz";
          if (atitle.length === 0 || btitle.length === 0) {
              return atitle.length - btitle.length;
          }
          for (var i = 0; i < atitle.length && i < btitle.length; i++) {
              var ai = alfabe.indexOf(atitle[i].toUpperCase());
              var bi = alfabe.indexOf(btitle[i].toUpperCase());
              if (ai !== bi) {
                  return ai - bi;
              }
          }
        })
  
        
         newCityList.map((item, index) => (
          setCityList(oldArray => [...oldArray,{label:item.name,value:item.iso2}])
         ))


         let filterDataCity= state._item.DynamicForm_Success.filter((x)=> x.Dynamic_Form_Extra.DynamicType == "İl Alanı")

         if(filterDataCity.length > 0){
   
           let filterDataUniveristy= state._item.DynamicForm_Success.filter((x)=> x.Dynamic_Form_Extra.DynamicType == "Üniversite Alanı")
   
           setselectcityNameEducation(filterDataCity[0].DynamicForm_Success_Value)
   
         if(filterDataUniveristy.length > 0){
         let data = allUniversity.filter(data => data.province == (filterDataCity[0].DynamicForm_Success_Value).toUpperCase())
              
         data[0].universities.map((item, index) => (
          setUniversityList(oldArray => [...oldArray,{label:item.name,value:item.name}])
          ))  

         setUniversitiesName(filterDataUniveristy[0].DynamicForm_Success_Value)
       }
    
       }

          })

          
  
  }


  
  const _handleChange= async (value,type) => { 
    if(type =="district"){

      setselectcityNameEducation(value.label)
  
      let data = allUniversity.filter(data => data.province == (value.label).toUpperCase())
  
    data[0].universities.map((item, index) => (
      setUniversityList(oldArray => [...oldArray,{label:item.name,value:item.name}])
    ))   
  
  } 

  if(type =="universityDepartmant"){

    setUniversitiesName(value.label)

 //   _getProgram()
}

if(type =="universityProgram"){
  setDepartmantName(value.label)
}

}


const validation = useFormik({
  // enableReinitialize : use this flag when initial values needs to be changed
  enableReinitialize: true,
  validate:values=>{
    const count = values.ibanNumber.length;
    
    if (count > 0) {
      settextareabadge(true);
    } if (count > 24) {
      settextareabadge(false);
    }

    settextcount(values.ibanNumber.length);

    if(values.ibanNumber.length != 24){
    //showToast(false,"Lütfen 24 Haneli Iban Alanını Doldurunuz!")    
  }
  },
  initialValues: {
    ibanNumber: "",
  },
  validationSchema: Yup.object({
    ibanNumber: Yup.string().required("Lütfen 24 haneli IBAN Numaranızı Eksiksiz Giriniz"),
  }),
  onSubmit: (values) => {
   
 if(values.ibanNumber.length == 24){
    if(getFileName.length<5){
      showToast(false,"Lütfen 5 Dosyayı da Sisteme Ekleyiniz!")    

    } if(getFileName.length==5){
      postForm(values)
    } 
  }else{
    showToast(false,"Lütfen 24 haneli IBAN Numaranızı Eksiksiz Giriniz!")   
    if(getFileName.length<5){
      showToast(false,"Lütfen 5 Dosyayı da Sisteme Ekleyiniz!")    

    } 
  }

   // postForm(values)
  },
});


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Form" breadcrumbItem="Form" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
               
                <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                    <Row>
                      <Col lg="12">

                      <div className="mt-3">
        <img alt="client-img" className="mx-auto img-fluid d-block" src={'https://megasubdomain.stechomeyazilim.info/' + state._item.Dynamic_Form_Banner} /> 
      </div>
      
      { getData.map((item, index) => {
                     let filterShort = getTextChange.filter((x)=> x.type == item.Title)
                     let filterFile = getFileName.filter((x)=> x.type == item.Title)
                     return(
                      <div key={index}>
                     { 
                     item.DynamicType == "İl Alanı" ?
                   
                     <div className="mt-3">
                   {getCityList.length > 0  ?  <SelectOption fullvalue={getCityList} handleChange={(e)=> _handleChange(e,"district")}  value={getselectcityNameEducation} selectLabel={"Kazanılan Üniversite İl Seçiniz"} type={"cityEducation"}/>  : null}          
                   </div>:
                   item.DynamicType == "Üniversite Alanı" ?
                   
                   <div className="mt-3">
                  {getUniversityList.length > 0  ? <SelectOption fullvalue={getUniversityList}  handleChange={(e)=> _handleChange(e,"universityDepartmant")} value={getUniversitiesName} selectLabel={"Üniversite"} type={"highSchool"}/> : null }

                 </div>:
                 item.DynamicType == "Program Alanı" ?
                   
                 <div className="mt-3">
                {getUniversityDepartmant.length > 0  ? <SelectOption fullvalue={getUniversityDepartmant}  handleChange={(e)=> _handleChange(e,"universityProgram")} value={getDepartmantName} selectLabel={"Bölüm Seçiniz"} type={"educationprogram"}/> : null }

               </div>:

                       item.DynamicType == "Cinsiyet Seçimi" ?
                   
                       <div className="mt-3">
                                             <label htmlFor="resume">{item.DynamicType}</label>
                         <input
                           placeholder={item.Title}
                           className="form-control"
                           type="text"
                           disabled
                           value={authData.Users_Gender}
                         />
                     </div>:
                     item.DynamicType == "TC Alanı" ?
                     <div className="mt-3">
                       <label htmlFor="resume">{item.DynamicType}</label>
                       <input
                         placeholder={item.Title}
                         className="form-control"
                         type="text"
                         disabled
                         value={authData.Users_TCNumber}
                       />
                   </div> : 

                     item.DynamicType == "Ad Soyad Alanı" ?
                   
                     <div className="mt-3">
                                           <label htmlFor="resume">{item.DynamicType}</label>
                       <input
                         placeholder={item.Title}
                         className="form-control"
                         type="text"
                         disabled
                         value={authData.NameSurname}
                       />
                   </div> : 
                   item.DynamicType == "Tek Seçim Aracı" ?
                      <div className="mt-3">
                      <label htmlFor="resume">{item.Title}</label>
                      <Select
                        value={selectedGroup}
                        placeholder={item.Title}
                        onChange={(text) => {
                          handleSelectGroup(text);
                        }}
                        options={
                          item.Dynamic_Form_Extra_Extra.map((item1, index) => {
                           return({ label: item1.Dynamic_Form_Extra_Extra_Title, value: item1.ID }) 
                          })}
                       className="select2-selection"
                      />
                    </div> :
                     item.DynamicType == "Çoklu Seçim Aracı" ?
                     <div className="mt-3">
                     <Select
                       value={selectedMulti}
                       isMulti={true}
                       placeholder={"Çoklu Seçim Yapınız"}
                       onChange={(text) => {
                         handleMulti(text,item.ID);
                       }}
                       options={
                        item.Dynamic_Form_Extra_Extra.map((item1, index) => {
                         return({ label: item1.Dynamic_Form_Extra_Extra_Title, value: item1.ID }) 
                        })}
                       className="select2-selection"
                     />
                   </div> :
                     item.DynamicType == "Tarih Aracı" ?
                     <div className="docs-datepicker mt-3">
                     <InputGroup>
                       <Flatpickr
                       
                         value={Date.parse(pick_date)}
                         className="form-control d-block"
                         placeholder="Tarih Seçiniz"
                         options={{
                           altInput: true,
                           dateFormat: "DD-MM-YYYY",
                           locale: { ...Turkish } 
                         }}
                         onChange={(text) => {
                          handleChangeDate(text,item.ID);
                        }}
                       />
                       <div className="input-group-append">
                         <button
                           type="button"
                           className="btn btn-outline-secondary docs-datepicker-trigger"
                           disabled>
                           <i
                             className="fa fa-calendar"
                             aria-hidden="true"
                           />
                         </button>
                       </div>
                     </InputGroup>
                     <div className="docs-datepicker-container" />
                   </div>:
                     item.DynamicType == "Listeli Seçim" ?
                     null:
                     item.DynamicType == "Şifre Alanı" ?
                     <div className="mt-3">
                                           <label htmlFor="resume">{item.Title}</label>
                     <Input
                       type="password"
                       onChange={e => {
                         textPasswordChange(e);
                       }}
 
                       placeholder="Şifrenizi Giriniz"
                     />
                    
                   </div> :
                     item.DynamicType == "Listeli Gösterim" ?
                     null :
                     item.DynamicType == "Uzun Yazı Alanı" ?
                     <div className="mt-3">
                     <Input
                       type="textarea"
                       id="textarea"
                       onChange={e => {
                        textAreaChangeLong(e);
                       }}
                       maxLength="225"
                       rows="3"
                       value={getTextAreaChangeLong}
                       placeholder="Bu metin alanının 225 karakterlik bir sınırı vardır."
                     />
                     {textareabadge ? (
                       <span className="badgecount badge bg-success">
                         {" "}
                         {textcount} / 225{" "}
                       </span>
                     ) : null}
                   </div>:
                      item.DynamicType == "Iban Alanı" && getTextChange.length > 0?
                      <div key={index} className="mt-3">
                      <label htmlFor="resume">{item.Title}</label>
                      <div className="d-flex ">
                       <div className="text-center mt-2 ">
                      <label htmlFor="resume">TR</label>
                      </div>

                     
                       <Input
                       name="ibanNumber"
                         placeholder={item.Title}
                         className="form-control p-2"
                         type="text"
                         validate={{
                           required: { value: true },
                         }}
                         onChange={validation.handleChange}
                         onBlur={validation.handleBlur}
                         value={validation.values.ibanNumber || ""}
                         invalid={
                           validation.touched.ibanNumber && validation.errors.ibanNumber ? true : false
                         }
                       />
                       {validation.touched.ibanNumber && validation.errors.ibanNumber ? (
                         <FormFeedback type="invalid">{validation.errors.ibanNumber}</FormFeedback>
                       ) : null}
                       
                     
                       </div>

                    
                       {textareabadge ? (
                     <span className="badgecount badge bg-success">
                       {" "}
                       {textcount} / 24{" "}
                     </span>
                   ) : null}

                   </div>:
                   item.DynamicType == "Yazı Alanı" ?
                     <div className="mt-3">
                      <label htmlFor="resume">{item.Title}</label>
                       <input
                         placeholder={item.Title}
                         className="form-control"
                         type="text"
                         value={filterShort.length > 0 ? filterShort[0].value : null}
                         onChange={e => {
                          textAreaChangeShort(e,item.Title);
                        }} 
                       />

                   </div>:
                   item.DynamicType == "Telefon Alanı" ?
                   <div className="mt-3">
                                         <label htmlFor="resume">{item.Title}</label>
                     <input
                       placeholder={item.Title}
                       className="form-control"
                       type="text"
                       disabled={true}
                       value={authData.GsmNumber}
                       onChange={e => {
                        textAreaChangeShort(e);
                      }}
                     />
                 </div> :
                    item.DynamicType == "Cinsiyet" ?
                    <div className="mt-3">
                                          <label htmlFor="resume">{item.Title}</label>
                      <input
                        placeholder={item.Title}
                        className="form-control"
                        type="text"
                        disabled={true}
                        value={authData.Users_Gender}
                        onChange={e => {
                         textAreaChangeShort(e);
                       }}
                      />
                  </div> :
                     item.DynamicType == "Adres Alanı" ?
                     <div className="mt-3">
                                           <label htmlFor="resume">{item.Title}</label>
                       <input
                         placeholder={item.Title}
                         className="form-control"
                         type="text"
                         disabled={true}
                         value={authData.Adress}
                         onChange={e => {
                          textAreaChangeShort(e);
                        }}
                       />
                   </div> :
                   
                   
                   item.DynamicType == "Sayı Alanı" ?
                   <div className="mt-3">
                    
                   <InputGroup>
                     <div
                       className="input-group-append"
                       onClick={() => {
                         setdata_attr(data_attr - 1);
                       }}
                     >
                       <Button type="button" color="primary">
                         <i className="mdi mdi-minus" />
                       </Button>
                     </div>
                     <input
                       type="number"
                       className="form-control"
                       value={data_attr}
                       placeholder="number"
                       readOnly
                     />
                     <div
                       className="input-group-append"
                       onClick={() => {
                         setdata_attr(data_attr + 1);
                       }}
                     >
                       <Button type="button" color="primary">
                         <i className="mdi mdi-plus" />
                       </Button>
                     </div>
                   </InputGroup>
                 </div>  : item.DynamicType == "Dosya Yükleme Alanı" ?
                   <div className="mt-3">     
                    <Row>
                    <Col xs={12} className="mb-3">
                     <label htmlFor="resume">{item.Title}</label>
                             <Dropzone
                                   inputContent="Dosya Yükle"
              getUploadParams={getUploadParams}
              onChangeStatus={(file) => handleChangeStatus(file,item.Title)}
              maxFiles={1}
              styles={{
                dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                inputLabelWithFiles: { margin: '20px 3%' }
              }}
              canRemove={false}
              PreviewComponent={Preview}
              accept="image/*,application/pdf"
            />
                    
                              </Col>

                              { filterFile.map((item, index) => {
                                return(
                                  item.fileType == "application/pdf" ?
                                  <div>
                                  <a rel="noreferrer" target="_blank" href={'https://megasubdomain.stechomeyazilim.info/' +  item.value}>Dosyayı Gör</a>
                                  </div> :
                                  item.fileType ==  "image/jpeg" ?
                                  <Row key={index}>
                                  <label htmlFor="resume">Mevcut Eklenen Dosya</label>
                  
                                  <Col xs={12} className="mb-3">
                                              <img
                                    className=" avatar-xl"
                                    src={'https://megasubdomain.stechomeyazilim.info/' +  item.value}
                                    alt=""
                                  />
                                            </Col>
                                  </Row> : 
                                   item.fileType ==  "image/png" ?
                                   <Row key={index}>
                                   <label htmlFor="resume">Mevcut Eklenen Dosya</label>
                   
                                   <Col xs={12} className="mb-3">
                                               <img
                                     className=" avatar-xl"
                                     src={'https://megasubdomain.stechomeyazilim.info/' +  item.value}
                                     alt=""
                                   />
                                             </Col>
                                   </Row>: null)

                                
                              })}

                    </Row>
                    </div>: null
                 }
                     </div>
                    )})
                    
                   
                     }  
                     
                      </Col>

                    
                     <button
                             

                              type="submit"
                        className="mt-3 btn btn-primary btn-block "
                            >
                              Formu Güncelle
                            </button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

      

       

      
      
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FormAdvanced;
