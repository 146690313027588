import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { map } from "lodash";
import {
  Badge,
  Card,
  CardBody,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import images from "assets/images";
import companies from "assets/images/companies";

const CardProject = ({ projects }) => {
  return (
    <React.Fragment>
      {map(projects, (project, key) => (
        <Col xl="4" sm="6" key={key}>
          <Card>
            <CardBody>
              <div className={`${key % 2 == 0 ?"d-flex  bg-danger" : "d-flex  bg-warning" } `}>
                <div className="avatar-md me-4">
                  <span className="avatar-title bg-light text-danger font-size-16">
                    <img src={'https://megasubdomain.stechomeyazilim.info/' + project.SUBDOMAIN_BannerPhotoLink} alt="" height="30" />
                  </span>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="text-truncate font-size-15">
                    <a
                      href={project.SUBDOMAIN_Link}
                      className="text-dark"
                      target="blank"
                    >
                      {project.SUBDOMAIN_Title}
                    </a>
                  </h5>

               
                </div>
              </div>
            </CardBody>
       
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

CardProject.propTypes = {
  projects: PropTypes.array,
};

export default CardProject;
