import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname, Email, GSM,Date } from "./contactlistCol";
import {ExportToExcel} from './ExportToExcel'
import { Button } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import jsPDF from "jspdf";
import "jspdf-autotable";

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Kullanıcı Listesi | MEGA ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [toggleSwitch, settoggleSwitch] = useState(true);
  const [getIsActive, setIsActive] = useState(false);
  const [getWorkshopText, setWorkshopText] = useState(null);
  const [getUsersTypeID, setUsersTypeID] = useState(null);


  // validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      tcNumber: (contactEdit && contactEdit.Users_TCNumber) || "",
      name: (contactEdit && contactEdit.NameSurname) || "",
      gsm: (contactEdit && contactEdit.GsmNumber) || "",
      email: (contactEdit && contactEdit.MailAdress) || "",
      district: (contactEdit && contactEdit.District) || "",
      adress: (contactEdit && contactEdit.Adress) || "",
    },
    validationSchema: Yup.object({
      tcNumber: Yup.string(11).required("TC Numaranızı Giriniz"),
      name: Yup.string().required("Adınızı Giriniz"),
     /* email: Yup.string().matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Mail Formatında Giriniz"
      ).required("Lütfen Mail Adresinizi Giriniz"), */
      adress: Yup.string().required("Adresinizi Giriniz"),
      district: Yup.string().required("İlçe Giriniz"),
      adress: Yup.string().required("Adres Giriniz"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          ID: contactEdit.ID,
          NameSurname:values.name,
          GsmNumber: values.gsm,
          District:values.district,
          Adress:values.district,
          Users_TCNumber:values.tcNumber,
          MailAdress: values.email,
          Users_Type_ID:getUsersTypeID
       //   Users_IsActive:toggleSwitch
        };
        dispatch(onUpdateUser(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          NameSurname: values["name"],
          GsmNumber: values["gsm"],
         // CreatedDateTime: values.orderdate,
          District: values["district"],
          Adress: values["adress"],
          Users_Type_ID: 1,
       //   UsersPhoto: values["adress"],
          MailAdress:values["email"],
          Users_TCNumber:values["tcNumber"],
        //  Users_Birthday:values["adress"],
          Users_IsActive:getIsActive

        };
        // save new user
        dispatch(onAddNewUser(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.users,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [getAuthData, setAuthData] = useState([]);
  
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Foto",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.UsersPhoto ? (
              <div className="avatar-xs">
              {cellProps.NameSurname != null ?  <span className="avatar-title rounded-circle">
                  {cellProps.NameSurname.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={'https://megaapi.stechomeyazilim.info'+cellProps.UsersPhoto}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Adı Soyadı",
        accessor: "NameSurname",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      {
        Header: "Mail Adresi",
        accessor: "MailAdress",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },
      {
        Header: "GSM",
        accessor: "GsmNumber",
        filterable: true,
        Cell: cellProps => {
          return <GSM {...cellProps} />;
        },
      },
      {
        Header: "Kullanıcı Türü",
        accessor: "Users_Type.Users_Type_Title",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <GSM {...cellProps} />;
        },
      },
      {
        Header: 'Kayıt Tarihi',
        accessor: 'CreatedDateTime',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },
      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

<Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-plus font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Alt Metin Ekle
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const _getAuthData= async () => {
  
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getUsersTypeMega/select/`)
    .then((res) => {
      setAuthData(res.data)
    })
  }

  useEffect(() => {
    _getAuthData()
    if (users && !users.length) {
      dispatch(onGetUsers());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
   // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);
    settoggleSwitch(user.Users_IsActive)
    setIsEdit(true);

    setUsersTypeID(user.Users_Type_ID)
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.id) {
      dispatch(onDeleteUser(contact.id));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const _setChoiceAuth = arg => {
    setUsersTypeID(arg.target.value)
   }

  const keyField = "id";
  const fileName = "myfile"; // here enter filename for your excel file

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Kullanıcı Listesi";
    const headers = [["Adı", "Telefon Numarasi", "İlçe", "Adres", "Mail Adresi"]];

    const data = users.map(elt=> [elt.NameSurname, elt.GsmNumber, elt.District, elt.Adress, elt.MailAdress]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf")
  }
  
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Kullanıcı Listesi" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
      
                <ExportToExcel apiData={users} fileName={fileName} />

                <Button
    type="button"
    color="danger"
    className="btn  mb-2 me-2"
    onClick={exportPDF}>
       <i className="mdi mdi-export me-1" />
                     PDF
                </Button>
                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddUserList={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Üye Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                          <div className="mb-3">
                              <Label className="form-label">T.C Kimlik Numarası</Label>
                              <Input
                                name="tcNumber"
                                type="text"
                                disabled={isEdit == true ? true: false }
                                placeholder="T.C Kimlik Numarası"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.tcNumber || ""}
                                invalid={
                                  validation.touched.tcNumber &&
                                    validation.errors.tcNumber
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.tcNumber &&
                                validation.errors.tcNumber ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.tcNumber}
                                </FormFeedback>
                              ) : null}
                            </div>
                            
                         

                            <div className="mb-3">
                              <Label className="form-label">Ad-Soyad</Label>
                              <Input
                                name="name"
                                type="text"
                                disabled={isEdit == true ? true: false }
                                placeholder="Ad ve Soyad"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                    validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                                validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">GSM</Label>
                              <Input
                                name="gsm"
                                label="GSM"
                                type="text"
                                placeholder="GSM Numaranızı Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.gsm || ""}
                                invalid={
                                  validation.touched.gsm &&
                                    validation.errors.gsm
                                    ? true
                                    : false
                                }
                              />
                               {validation.touched.gsm &&
                                validation.errors.gsm ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.gsm}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Mail</Label>
                              <Input
                                name="email"
                                label="Email"
                                type="email"
                                placeholder="Mail Adresini Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                    validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                                validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                      <Label className="form-label">Rol Seçiniz</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        defaultValue={getUsersTypeID}
                        onChange={(text) => _setChoiceAuth(text)}
                        value={
                          getWorkshopText
                        }>
                        {getAuthData.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.Users_Type_Title}</option>
                        ))}
                       
                      </Input>
                      {validation.touched.wsText && validation.errors.wsText ? (
                        <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                      ) : null}
                    </div>

                            <div className="mb-3">
                          
                            <Label className="form-label">Doğum Tarihi</Label>

                      <input
                        className="form-control"
                        type="date"
                        disabled={isEdit == true ? true: false }
                        // value={contactEdit && moment(contactEdit.Users_Birthday).format('yyyy-mm-DD')}
                        defaultValue="2019-08-19"
                        id="example-date-input"
                      />
                   
                  </div>

                            <div className="mb-3">
                              <Label className="form-label">İlçe</Label>
                              <Input
                                disabled={isEdit == true ? true: false }
                                name="district"
                                label="Projects"
                                type="text"
                                placeholder="İlçe"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.district || ""}
                                invalid={
                                  validation.touched.district &&
                                    validation.errors.district
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.district &&
                                validation.errors.district ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.district}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Adres</Label>
                              <Input
                                disabled={isEdit == true ? true: false }
                                name="adress"
                                label="Adres"
                                type="text"
                                placeholder="Adres"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.adress || ""}
                                invalid={
                                  validation.touched.adress &&
                                    validation.errors.adress
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.adress &&
                                validation.errors.adress ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.adress}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>

                        <div className="square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>

                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
