import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_POPUP,
  GET_WORKSHOP,
  GET_CourseSchedulesMega,
  GET_WorkshopSessionMega,
  GET_TeacherMega,
  GET_EVENT,
  GET_DYNAMICFORM,
  GET_SLIDER,
  GET_PROJECTSNEW,
  GET_NEWS,
  GET_MEGA,
  GET_CONTACT,
  GET_AUTHORITY,
  GET_WORKSHOP2,
  GET_WORKSHOP2_SUCCESS,
  GET_GALLERY,
  GET_GALLERY_SUCCESS,
  GET_REFERANCES,
  ADD_REFERANCES,
  GET_SUBPROJECT,

  UPDATE_WORKSHOP2,
  UPDATE_SUBDOMAIN_SUCCESS,
  UPDATE_AUTH,
  UPDATE_CONTACT,
  UPDATE_SUCCESS_COURSESCHEDULES,
  UPDATE_WORKSHOPSESSION_SUCCESS,
  UPDATE_WORKSHOP2_SUCCESS,
  UPDATE_GALLERY,

  GET_REFERANCES_SUCCESS,
  ADD_WORKSHOP2,
  ADD_APPSETTING,
  ADD_WORKSHOPSUBDOMAIN,
  ADD_COURSE,
  ADD_MEGA,
  ADD_NEWS,
  ADD_PROJECT,
  ADD_SLIDER,
  ADD_POPUP,
  UPDATE_NEWS,
  UPDATE_PROJECT,
  UPDATE_SLIDER,
  UPDATE_TEACHER,
  UPDATE_WORKSHOPSESSION,
  UPDATE_COURSESCHEDULES,
  UPDATE_SUBDOMAIN,
  UPDATE_POPUP,
  GET_POPUP_SUCCESS,
  GET_WORKSHOP_SUCCESS,
  GET_WORKSHOPSESSION_SUCCESS,
  GET_APPLICATION_SUCCESS,
  GET_SLIDER_SUCCESS,
  GET_PROJECTS_SUCCESS,
  GET_NEWS_SUCCESS,
  GET_MEGA_SUCCESS,
  GET_TEACHER_SUCCESS,
  GET_DYNAMIC_SUCCESS,
  GET_AUTH_SUCCESS,
  GET_AUTHUPDATE_SUCCESS,
  UPDATE_SUCCESS_TEACHER,
  UPDATE_SUCCESS_SLIDER,
  UPDATE_SUCCESS_PROJECT,
  UPDATE_SUCCESS_NEWS,
  UPDATE_SUCCESS_MEGA,
  UPDATE_MEGA,
  UPDATE_SUCCESS_GALLERY,

  UPDATE_REFERANCES,
  UPDATE_REFERANCES_SUCCESS,

  GET_SUBPROJECT_SUCCESS,

  GET_ACTIVE_WORKSHOP,
  GET_ACTIVE_WORKSHOP_SUCCESS,
  ADD_APPLY,
  ADD_APPLY_SUCCESS,
  GET_CLASSATTENDANCE,
  GET_SUCCESS_ATTENDANCE,

  GET_DYNAMICEXTRAFORM,
  GET_DYNAMICEXTRAFORM_SUCCESS,

  GET_FORM,
  GET_FORM_SUCCESS
} from "./actionTypes"

export const addSuccessApply = user => ({
  type: ADD_APPLY_SUCCESS,
  payload: user,
})

export const getForm = teacher => ({
  type: GET_FORM,
  payload: teacher,
})


export const getFormSuccess = teacher => ({
  type: GET_FORM_SUCCESS,
  payload: teacher,
})



export const getSuccessAttendance = teacher => ({
  type: GET_SUCCESS_ATTENDANCE,
  payload: teacher,
})


export const getSubProjectSuccess = teacher => ({
  type: GET_SUBPROJECT_SUCCESS,
  payload: teacher,
})


export const getReferancesSuccess = teacher => ({
  type: GET_REFERANCES_SUCCESS,
  payload: teacher,
})


export const updateGallerySuccess = user => ({
  type: UPDATE_SUCCESS_GALLERY,
  payload: user,
})


export const getAuthority = () => ({
  type: GET_AUTHORITY,
})



export const updateWorkShop2Success = user => ({
  type: UPDATE_WORKSHOP2_SUCCESS,
  payload: user,
})

export const updateReferances = user => ({
  type: UPDATE_REFERANCES,
  payload: user,
})
export const updateMega = user => ({
  type: UPDATE_MEGA,
  payload: user,
})

export const updateMegaSuccess = news => ({
  type: UPDATE_SUCCESS_MEGA,
  payload: news,
})

export const updateNewsSuccess = news => ({
  type: UPDATE_SUCCESS_NEWS,
  payload: news,
})

export const updateSuccessProject = teacher => ({
  type: UPDATE_SUCCESS_PROJECT,
  payload: teacher,
})

export const updateSliderSuccess = teacher => ({
  type: UPDATE_SUCCESS_SLIDER,
  payload: teacher,
})


export const updateTeacherSuccess = teacher => ({
  type: UPDATE_SUCCESS_TEACHER,
  payload: teacher,
})

export const updateSuccessCourseSchedules = teacher => ({
  type: UPDATE_SUCCESS_COURSESCHEDULES,
  payload: teacher,
})





export const getDynamicFormExtraSuccess = teacher => ({
  type: GET_DYNAMICEXTRAFORM_SUCCESS,
  payload: teacher,
})

export const getDynamicFormExtra = teacher => ({
  type: GET_DYNAMICEXTRAFORM,
  payload: teacher,
})

export const getAuthUpdateSuccess = teacher => ({
  type: GET_AUTHUPDATE_SUCCESS,
  payload: teacher,
})

export const getAuthSuccess = teacher => ({
  type: GET_AUTH_SUCCESS,
  payload: teacher,
})
export const getDynamicSuccess = teacher => ({
  type: GET_DYNAMIC_SUCCESS,
  payload: teacher,
})

export const getTeacherSuccess = teacher => ({
  type: GET_TEACHER_SUCCESS,
  payload: teacher,
})

export const getGallerySuccess = mega => ({
  type: GET_GALLERY_SUCCESS,
  payload: mega,
})


export const getMegaSuccess = mega => ({
  type: GET_MEGA_SUCCESS,
  payload: mega,
})


export const getSubProject = news => ({
  type: GET_SUBPROJECT,
  news,
})


export const getClassAttendance = news => ({
  type: GET_CLASSATTENDANCE,
  news,
})

export const getWorkShop2 = news => ({
  type: GET_WORKSHOP2,
  payload: news,
})


export const getNewsSuccess = news => ({
  type: GET_NEWS_SUCCESS,
  payload: news,
})

export const getProjectSuccess = projects => ({
  type: GET_PROJECTS_SUCCESS,
  payload: projects,
})

export const getSliderSuccess = slider => ({
  type: GET_SLIDER_SUCCESS,
  payload: slider,
})

export const getApplicationSuccess = workshop => ({
  type: GET_APPLICATION_SUCCESS,
  payload: workshop,
})
export const getWorkshopSessionSuccess = workshop => ({
  type: GET_WORKSHOPSESSION_SUCCESS,
  payload: workshop,
})
export const getWorkshopSuccess = workshopApply => ({
  type: GET_WORKSHOP_SUCCESS,
  payload: workshopApply,
})


export const updateWorkShopSessionSuccess = user => ({
  type: UPDATE_WORKSHOPSESSION_SUCCESS,
  payload: user,
})

export const updateSubdomainSuccess = user => ({
  type: UPDATE_SUBDOMAIN_SUCCESS,
  payload: user,
})

export const updateAuth = user => ({
  type: UPDATE_AUTH,
  payload: user,
})

export const updateContact = user => ({
  type: UPDATE_CONTACT,
  payload: user,
})

export const updateNews = user => ({
  type: UPDATE_NEWS,
  payload: user,
})


export const updateProject = user => ({
  type: UPDATE_PROJECT,
  payload: user,
})

export const updateSlider = user => ({
  type: UPDATE_SLIDER,
  payload: user,
})


export const getWorkshop2Success = popup => ({
  type: GET_WORKSHOP2_SUCCESS,
  payload: popup,
})


export const getPopupSuccess = popup => ({
  type: GET_POPUP_SUCCESS,
  payload: popup,
})


export const updateTeacher = user => ({
  type: UPDATE_TEACHER,
  payload: user,
})


export const updateWorkshop2 = user => ({
  type: UPDATE_WORKSHOP2,
  payload: user,
})


export const updateWorkshopSession = user => ({
  type: UPDATE_WORKSHOPSESSION,
  payload: user,
})



export const updateCourseSchedules = user => ({
  type: UPDATE_COURSESCHEDULES,
  payload: user,
})

export const updateSubdomain = user => ({
  type: UPDATE_SUBDOMAIN,
  payload: user,
})


export const updatePopup = user => ({
  type: UPDATE_POPUP,
  payload: user,
})


export const getReferances = () => ({
  type: GET_REFERANCES,
})

export const getContactMega = () => ({
  type: GET_CONTACT,
})

export const getMegaMega = () => ({
  type: GET_MEGA,
})

export const getNews = () => ({
  type: GET_NEWS,
})

export const getProjectsNew = () => ({
  type: GET_PROJECTSNEW,
})


export const getSlider = () => ({
  type: GET_SLIDER,
})

export const getDynamicForm = () => ({
  type: GET_DYNAMICFORM,
})


export const getEventMega = () => ({
  type: GET_EVENT,
})

export const getTeacherMega = () => ({
  type: GET_TeacherMega,
})

export const getWorkshopSessionMega = () => ({
  type: GET_WorkshopSessionMega,
})


export const getActiveWorkShop = news => ({
  type: GET_ACTIVE_WORKSHOP,
  news,
})

export const getActiveWorkShopSuccess = users => ({
  type: GET_ACTIVE_WORKSHOP_SUCCESS,
  payload: users,
})


export const getWorkShop = news => ({
  type: GET_WORKSHOP,
  news,
})


export const getPopup = () => ({
  type: GET_POPUP,
})

export const getCourseSchedulesMega = () => ({
  type: GET_CourseSchedulesMega,
})



export const getGallery = () => ({
  type: GET_GALLERY,
})

export const getUsers = () => ({
  type: GET_USERS,
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})



export const addApply = user => ({
  type: ADD_APPLY,
  payload: user,
})

export const addReferances = user => ({
  type: ADD_REFERANCES,
  payload: user,
})

export const updateSuccessReferances = user => ({
  type: UPDATE_REFERANCES_SUCCESS,
  payload: user,
})

export const addNewWorkshop2 = user => ({
  type: ADD_WORKSHOP2,
  payload: user,
})


export const addAppSetting = user => ({
  type: ADD_APPSETTING,
  payload: user,
})

export const addWorkshopSubdomain = user => ({
  type: ADD_WORKSHOPSUBDOMAIN,
  payload: user,
})

export const addCourse = user => ({
  type: ADD_COURSE,
  payload: user,
})

export const addNewMega = user => ({
  type: ADD_MEGA,
  payload: user,
})

export const addNews = user => ({
  type: ADD_NEWS,
  payload: user,
})

export const addProject = user => ({
  type: ADD_PROJECT,
  payload: user,
})

export const addSlider = user => ({
  type: ADD_SLIDER,
  payload: user,
})

export const addPopup = user => ({
  type: ADD_POPUP,
  payload: user,
})

export const addNewUser = user => ({
  type: ADD_NEW_USER,
  payload: user,
})


export const addSuccessReferances = user => ({
  type: ADD_REFERANCES_SUCCESS,
  payload: user,
})


export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const getUserProfile = () => ({
  type: GET_USER_PROFILE,
})

export const getUserProfileSuccess = userProfile => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: userProfile,
})

export const getUserProfileFail = error => ({
  type: GET_USER_PROFILE_FAIL,
  payload: error,
})


export const updateGallery = user => ({
  type: UPDATE_GALLERY,
  payload: user,
})

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user,
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

export const deleteUser = user => ({
  type: DELETE_USER,
  payload: user,
})

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})
