import PropTypes from "prop-types";
import React, { useEffect, useState,useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";


//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
  getForm as onGetForm,
  getActiveWorkShop as onGetActiveWorkShop,
  getWorkShop as onGetWorkShop,

} from "store/contacts/actions";

import {
  Date,
  Name,
}  from "./EcommerceOrderCol";

import TableContainer from '../../components/Common/TableContainer';
import { Link } from 'react-router-dom';
import axios from "axios";

const Dashboard = props => {
  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);

  {/* useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true);
    }, 2000);
  }, []); */}

  const dispatch = useDispatch();

  const { orders } = useSelector(state => ({
    orders: state.contacts.form,
  }));

  const { ordersAtölye } = useSelector(state => ({
    ordersAtölye: state.contacts.workshopApply,
  }));
    

  const { wp } = useSelector(state => ({
    wp: state.contacts.workshop,
  }));

  const [getAllMyApply, setAllMyApply] = useState([]);


  /*useEffect(async() => {
  
    if (getAllMyApply && !getAllMyApply.length) {
      let authData = JSON.parse(localStorage.getItem("authUser"));

      await axios.get(`https://getjob.stechomeyazilim.info:5101/getSearchSUBDOMAINMega2/select/${authData.ID}`)
      .then((res) => {
        setAllMyApply(res.data)
      })
      
     }
  }, [dispatch, getAllMyApply]);*/

  {/* const reports = [
    {
      title: "Toplam Başvuru Formu",
      iconClass: "bx-purchase-tag-alt",
      description: orders.length + " Adet",
    },

    {
      title: "Başvurduğunuz Form Sayısı",
      iconClass: "bx-purchase-tag-alt",
      description: orders.length > 0 ? orders[0].DynamicForm_Success_User.length + " Adet" : null,
    }
  ]; */}

  const reports = [
    {
      title: "Toplam Atölye",
      iconClass: "bx-purchase-tag-alt",
      description: ordersAtölye.length + " Adet",
    },
    {
      title: "Toplam Başvuru",
      iconClass: "bx-purchase-tag-alt",
      description: getAllMyApply.length + " Adet",
    }
  ];

  useEffect(() => {

    if (ordersAtölye && !ordersAtölye.length) {
      let userDetail = localStorage.getItem("authUser");
      dispatch(onGetActiveWorkShop(JSON.parse(userDetail).ID));
    }
  }, [dispatch, ordersAtölye]);

  useEffect(() => {
    if (orders && !orders.length) {
     let authData = localStorage.getItem("authUser") != null ? localStorage.getItem("authUser") : null
 
     if(authData != null){
      dispatch(onGetForm(JSON.parse(authData).ID));
     }
     }
   }, [dispatch, orders]);


  useEffect(() => {
    dispatch(onGetChartsData("yearly"));
  }, [dispatch]);

  //meta title
  document.title = "Anasayfa | MEGA Öğrenci Paneli";

  let userType =localStorage.getItem("authUser") != null ? JSON.parse(localStorage.getItem("authUser")).Users_Type.Users_Type_Title : null

  const columns = useMemo(
    () => [
      {
        Header: 'Form Adı',
        accessor: 'Dynamic_Form_Name',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
    
     
      {
        Header: 'Oluşturulma Tarihi',
        accessor: 'Dynamic_Form_CreatedDateTime',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },
      {
        Header: 'İşlemler',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {          
          return (
            cellProps.row.original.DynamicForm_Success_User.length == 0 ?
            <div className="d-flex gap-3">
              <Link
                to="/addForm"
                className="text-success text-center"
                state={{ _item: cellProps.row.original }}              
              >
               Formu Doldur
              </Link>
            </div>: 
              <div className="d-flex gap-3">
                {cellProps.row.original.DynamicForm_Success_User[0].EditForm == true ? 
                <Link
                className="text-danger text-center"
              >
               Form Düzenleme Süresi 09/10/2023 15:00 itibariyle kapatılmıştır.
              </Link>:

              <p className="text-danger text-center">
               Formu Dolduruldunuz
              </p>}
            </div>
          )
        }
      },
    ],
    []
  );
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Yönetim")}
            breadcrumbItem={props.t("Anasayfa")}
          />

          <Row>
            <Col xl="4">
              <WelcomeComp />
            
            </Col>
            {userType != "Üniversite" ?  
             <Col xl="8">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

       
            </Col> : 
            <Col xl="8">
            <Row>
              {/* Reports Render */}
              {reports.map((report, key) => (
                <Col md="4" key={"_col_" + key}>
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            {report.title}
                          </p>
                          <h4 className="mb-0">{report.description}</h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i
                              className={
                                "bx " + report.iconClass + " font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>

     
          </Col>}
          </Row>


        

        </Container>
      </div>

      {/* subscribe ModalHeader */}
      <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal);
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setSubscribemodal(!subscribemodal);
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-checkbox-marked-circle"></i>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10 ">
                <h4 className="text-primary">Başvuru!</h4>
                <p className="text-bold font-size-16 mb-4">
                 Aşağıdaki Forma Başvurmak İster Misin?
                </p>

               {orders.map((item, key) => (
                <div className="mb-2 border-bottom border-4" key = {key}>
             {item.DynamicForm_Success_User.length == 0 ?
            <div className="float-end p-1">
              <Link
                to="/addForm"
                className="text-success text-center"
                state={{ _item: item }}              
              >
               Formu Doldur
              </Link>
            </div>: 
              <div className="float-end p-1 text-center">
              {item.DynamicForm_Success_User[0].EditForm == true ? 
                <p
              
                className="text-danger text-center"
              >
               Form Düzenleme Süresi 09/10/2023 15:00 itibariyle kapatılmıştır.
              </p>:

              <p
                className="text-danger text-center"
              >
               Formu Dolduruldunuz
              </p>}
            </div>}

                  <div className=" items-center justify-center text-center  mb-2">
                   <p className="text-bold text-center font-size-15">{item.Dynamic_Form_Name}</p>
                  </div>
               
                </div>
               ))}
               

             
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal);
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>

   


    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
