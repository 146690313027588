import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';
import Dropzone from 'react-dropzone-uploader';
import {Base64} from 'js-base64';

import {
  addNewOrder as onAddNewOrder,
  updateMega as onUpdateMega,
  deleteOrder as onDeleteOrder,
} from "store/actions";

import {
  addNewMega as onAddNewMega,
  getMegaMega as onGetMega,
} from "store/contacts/actions";

import {
  Name
}
  from "./EcommerceOrderCol";
  import { CKEditor } from "@ckeditor/ckeditor5-react";
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//redux
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";

function EcommerceOrder() {

  //meta title
  document.title = "MEGA | Mega - Admin Paneli";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);

  const [getMegaEdit, setMegaEdit] = useState(null);
  const [getHtmlData, setHtmlData] = useState();
  const [getFileName, setFileName] = useState('');

  
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (getMegaEdit && order.Mega_Title) || '',
      orderDesc: (getMegaEdit && order.Mega_Desc) || '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Lütfen Başlık Giriniz")
            }),
    onSubmit: (values) => {

      let newBase64 = Base64.encode(getHtmlData)
      if (isEdit) {
        const updateOrder = {
          ID: order ? order.ID : 0,
          Mega_Title: values.title,
         Mega_BannerImage: getFileName,
          Mega_Desc: newBase64,  
        };
        // update order
        dispatch(onUpdateMega(updateOrder));
        validation.resetForm();
      } else {
        const newOrder = {
          Mega_Title: values["title"],
          Mega_BannerImage: getFileName,
        //  Mega_Desc: values["orderId"],
        };
        // save new order
        dispatch(onAddNewMega(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });


  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }
   
  // handle the status of the file upload
  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();
  const { users } = useSelector(state => ({
    users: state.contacts.mega,
  }));

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetMega());
      setIsEdit(false)
    }
  }, [dispatch, users]);

  useEffect(() => {
    setOrderList(users);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setOrderList(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = arg => {
    const order = arg;
    setOrder(order);
    setMegaEdit(order)
    setHtmlData(Base64.decode(order.Mega_Desc))
    setFileName(order.Mega_BannerImage)
    setIsEdit(true);

    toggle();
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.id) {
      dispatch(onDeleteOrder(order.id));
      setDeleteModal(false);
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Banner Görseli",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.Mega_BannerImage ? (
              <div className="avatar-xs">
               {cellProps.Mega_Title != null ? <span className="avatar-title rounded-circle">
                  {cellProps.Mega_Title.charAt(0)}
                </span> : null }
              </div>
            ) : (
              <div>
                <img
                  className="avatar-xl"
                  src={'http://megasubdomain.stechomeyazilim.info/' +  cellProps.Mega_BannerImage}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Mega Başlığı",
        accessor: "Mega_Title",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: 'İşlemler',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleOrderClick(orderData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Ecommerce" breadcrumbItem="MEGA" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Mega Düzenle" : "Mega Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
               
                    <div className="mb-3">
                      <Label className="form-label">Başlık</Label>
                      <Input
                        name="title"
                        type="text"
                        placeholder="Başlık Giriniz"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.title || ""}
                        invalid={
                          validation.touched.title && validation.errors.title ? true : false
                        }
                      />
                      {validation.touched.title && validation.errors.title ? (
                        <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                      ) : null}
                    </div>
              
                    <div className="mb-3">
                    <Label className="form-label">İçerik</Label>
                    <Form method="post">
                    <CKEditor
                      editor={ClassicEditor}
                      data={getHtmlData}
                      
                      onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                      }}
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                       
                        setHtmlData(data)
                    } }
                    />
                  </Form>
                  </div>

                  <div className="mb-3">
                <Col xs={12} className="mb-3">
                            <label htmlFor="resume">Görsel(800*600)</label>
                         <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          styles={{
            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
            inputLabelWithFiles: { margin: '20px 3%' }
          }}
          
          canRemove={false}
          PreviewComponent={Preview}
          accept="image/*"
        />
                
                          </Col>
                </div>
                 
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Kaydet
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;