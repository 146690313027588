import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-rangeslider/lib/index.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormik } from "formik";
import moment from "moment";

import * as Yup from "yup";

import {
  updateContact as onUpdateConcat,
  getContactMega as onGetContact,
} from "store/contacts/actions";

const EcommerceAddProduct = () => {
  document.title = "Kişisel Bilgilerim | MEGA Öğrenci Paneli";

  const dispatch = useDispatch();

  const [getContactEdit, setContactEdit] = useState(null);

  let authUser =  JSON.parse(localStorage.getItem("authUser"))
  
  console.log("authUser123",authUser.NameSurname)
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      namesurname:authUser.NameSurname,
      gsmNumber:authUser.GsmNumber,
      email:authUser.MailAdress,
      createddatetime:moment(authUser.CreatedDateTime).format('DD/MM/YYYY'),
    },
    validationSchema: Yup.object({
      namesurname: Yup.string().required("Lütfen Adınızı Giriniz")
            }),
    onSubmit: (values) => {
             const updateOrder = {
          ID: users ? users[0].ID : 0,
          Mega_Title: values.title,
       
        };
        // update order
        dispatch(onUpdateConcat(updateOrder));
        validation.resetForm();
    
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.mega,
  }));

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetContact());
        }
  }, [dispatch, users]);

  useEffect(() => {
    setContactEdit(users[0]);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users)) {
      setContactEdit(users[0]);
    }
  }, [users]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="İletişim Bilgileri" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>

                  <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                    <Row>
                   
                        <div className="mb-3">
                          <Label htmlFor="productname">Kullanıcı Adı</Label>
                          <Input
                            id="adress"
                            name="namesurname"
                            type="text"
                            className="form-control"
                            placeholder="Kullanıcı Adı"
                            validate={{
                              required: { value: true },
                            }}
                            disabled={"true"}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.namesurname || ""}
                            invalid={
                              validation.touched.namesurname && validation.errors.namesurname ? true : false
                            }
                          />
                          {validation.touched.namesurname && validation.errors.namesurname ? (
                            <FormFeedback type="invalid">{validation.errors.namesurname}</FormFeedback>
                          ) : null}
                         
                        </div>
                      

                        <div className="mb-3">
                          <Label htmlFor="price">Telefon Numarası</Label>
                          <Input
                            id="gsmNumber"
                            name="gsmNumber"
                            type="text"
                            className="form-control"
                            placeholder="Telefon Numarası"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            disabled={"true"}
                            value={validation.values.gsmNumber || ""}
                            invalid={
                              validation.touched.gsmNumber && validation.errors.gsmNumber ? true : false
                            }
                          />
                          {validation.touched.gsmNumber && validation.errors.adgsmNumberess ? (
                            <FormFeedback type="invalid">{validation.errors.gsmNumber}</FormFeedback>
                          ) : null}
                        
                        </div>

                     
                        <div className="mb-3">
                          <Label htmlFor="email">Eposta</Label>
                          <Input
                            id="price"
                            name="email"
                            type="text"
                            className="form-control"
                            placeholder="Eposta Adresi"
                            disabled={true}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                         
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="createddatetime">Kayıt Tarihi</Label>
                          <Input
                            id="price"
                            name="createddatetime"
                            type="text"
                            className="form-control"
                            placeholder="Facebook Adresi"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            disabled={true}
                            value={validation.values.createddatetime || ""}
                            invalid={
                              validation.touched.createddatetime && validation.errors.createddatetime ? true : false
                            }
                          />
                          {validation.touched.createddatetime && validation.errors.createddatetime ? (
                            <FormFeedback type="invalid">{validation.errors.createddatetime}</FormFeedback>
                          ) : null}
                         
                        </div>


                    </Row>
                  
                  </Form>
                </CardBody>
              </Card>

        
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct
